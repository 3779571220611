import { ThemeContext } from 'app/styled/ThemeProvider'
import { useTaskQuery } from 'entities/tasks/api/query'
import { useAddAnnotationsMutation } from 'features/annotations'
import { notices } from 'features/notices'
import { Feature } from 'ol'
import GeoJSON from 'ol/format/GeoJSON'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
//@ts-ignore
import CopyPaste from 'ol-ext/interaction/CopyPaste'
import { selectTasksViewerUrlTaskId } from 'pages/viewer'
import { useViewerIdSlideState, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useContext, useEffect, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { useOS } from 'shared/lib/hooks'
import { IAnnotation } from 'types/IAnnotations'
import ICase from 'types/ICase'
import { IMapOl } from 'types/IMapOl'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'
import { findPADDING } from 'viewer/map/layers/annotations/lib/annotationsDrawHelpers'
import { setSlidePositioningForAnnotation } from 'viewer/map/layers/annotations/lib/helpers'
import { defaultStyle } from 'viewer/map/layers/olStyles'
import { getSingleFeatureFromGeoJson } from 'viewer/map/lib/utils'

type Props = {
  map: IMapOl
  viewerId: TViewerId
  featureId?: number
  mppX: number
}

function getCenterOfExtent(extent: number[]) {
  const X = extent[0] + (extent[2] - extent[0]) / 2
  const Y = extent[1] + (extent[3] - extent[1]) / 2
  return [X, Y]
}

const CopyPasteInteraction = ({ featureId, map, mppX, viewerId }: Props) => {
  const queryClient = useQueryClient()
  const { buffer } = useViewerMainSelector(viewerId)
  const { caseId, slideId } = useViewerIdSlideState(viewerId)
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const { data: task } = useTaskQuery(taskId)
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
  const hotkeyEnabled = caseRecord?.permissionLevel !== 'READ_ONLY' && task?.status !== 'PAUSED'
  const { mutateAsync: addAnnotations } = useAddAnnotationsMutation({ caseId, slideId })
  const viewerDispatch = useViewerDispatch(viewerId)
  const { isFastTravel } = useViewerPageProvided()

  const copyPaste = useRef<any>()

  const theme = useContext(ThemeContext)

  useEffect(() => {
    const layer = new VectorLayer({
      source: new VectorSource({}),
      style: [defaultStyle],
      zIndex: 100,
    })
    map.addLayer(layer)

    copyPaste.current =
      copyPaste.current ||
      new CopyPaste({
        condition: () => false,
        destination: layer.getSource(),
      })

    copyPaste.current.on('paste', async (e: any) => {
      const feature: Feature<any> = e.features[0]

      const slideAnnotationId = feature?.get('slideAnnotationId')
      if (!slideAnnotationId) {
        return
      }
      feature.unset('slideAnnotationId')
      feature.unset('description')
      const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, slideAnnotationId])

      if (!annotation?.data || !annotation?.type) {
        return
      }

      const geom = feature.getGeometry()
      const center = map.getView().getCenter()
      if (!center) {
        return
      }

      const [eX, eY] = center

      const [gX, gY] = getCenterOfExtent(geom.getExtent())
      const diff = [eX - gX, eY - gY]

      const newGeom = geom.clone()
      if (diff[0] === 0 && diff[1] === 0) {
        const PADDING = findPADDING(geom.getExtent())
        newGeom.translate(-PADDING, -PADDING)
      } else {
        newGeom.translate(diff[0], diff[1])
      }
      feature.setGeometry(newGeom)

      setSlidePositioningForAnnotation(feature, map.getView())

      const data = {
        formattedFeature: new GeoJSON().writeFeature(feature),
        type: 'ANNOTATION',
      }

      await addAnnotations({
        annotations: [
          {
            data,
            metric: annotation.metric,
            slideId,
            type: annotation.type,
            zindex: annotation.zindex || 0,
          },
        ],
      })
      viewerDispatch(viewerSlice.actions.setBuffer(undefined))
      layer.getSource().clear()
    })

    map.addInteraction(copyPaste.current)
    return () => {
      notices.close('paste')
      viewerDispatch(viewerSlice.actions.setBuffer(undefined))
      copyPaste.current && map.removeInteraction(copyPaste.current)
      map.removeLayer(layer)
    }
  }, [map, mppX])

  useHotkeys(
    `${useOS() === 'MacOS' ? 'Cmd+C' : 'Ctrl+C'}`,
    () => !isFastTravel && hotkeyEnabled && viewerDispatch(viewerSlice.actions.setBuffer(featureId)),
    {},
    [featureId, isFastTravel, hotkeyEnabled],
  )
  useHotkeys(
    `${useOS() === 'MacOS' ? 'Cmd+V' : 'Ctrl+V'}`,
    () => {
      if (buffer && !isFastTravel && hotkeyEnabled) {
        const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, buffer])
        const feature = getSingleFeatureFromGeoJson(annotation?.data?.formattedFeature)
        feature.set('slideAnnotationId', buffer)
        copyPaste.current?.paste({ features: [feature], silent: false })
      }
    },
    {},
    [buffer, isFastTravel, hotkeyEnabled],
  )

  useEffect(() => {
    if (!copyPaste.current) return

    if (!buffer) {
      copyPaste.current.copy({ features: [] })
      return
    }
    const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, buffer])
    if (!annotation) return
    const feature = getSingleFeatureFromGeoJson(annotation?.data?.formattedFeature)

    copyPaste.current.copy({ features: feature ? [feature] : [] })
  }, [buffer])

  useEffect(() => {
    if (buffer) {
      notices.pasteNotification({
        key: 'paste',
        onClose: () => viewerDispatch(viewerSlice.actions.setBuffer(undefined)),
        theme: theme,
      })
    } else {
      notices.close('paste')
    }
  }, [buffer, theme])

  return null
}

export default CopyPasteInteraction
