import { memo, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ISource from 'types/ISource'
import { INITIAL_BACKGROUND_SIZES, RIGHT_ANGLE, RIGHT_ANGLE_X_3 } from 'viewer/tools/ui/lib/constans'
import { getClipPath } from 'viewer/tools/ui/ViewerInfoToolPanelContainer'

export type GlassPreviewProps = {
  /** rotation - обновленный угол поворота */
  rotation?: number
  /** setRotation - обновление угла поворота */
  setRotation: (rotate: number) => void
  /** slideId - идентификатор слайда в текущем вьювере */
  slideId: number
  /** caseId - идентификатор случая */
  caseId: number
  /** source - источник слайда */
  source: ISource
  /** labelSrc - фото стекла */
  glassPreviewSrc: string
  /** backGroundSizes - параметры блока подложки */
  backGroundSizes: number[]
  /** setBackGroundSizes - установка параметров блока подложки */
  setBackGroundSizes: (a: number[]) => void
  /** maxHeight - максимальная допустимая высота блока */
  maxHeight: number
}

const StyledImage = styled.img`
  object-fit: contain;
`
const StyledWrap = styled.div<{ rotation?: number }>`
  clip-path: ${({ rotation }) => getClipPath(Number(rotation))};
`

export const GlassPreviewContainer = memo(
  ({ backGroundSizes, glassPreviewSrc, maxHeight, rotation, setBackGroundSizes, slideId }: GlassPreviewProps) => {
    const imageRef = useRef<HTMLImageElement>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [aspectRatio, setAspectRatio] = useState<number>()

    useEffect(() => {
      setBackGroundSizes(INITIAL_BACKGROUND_SIZES)
      setAspectRatio(undefined)
      setIsLoading(true)
    }, [slideId])

    useEffect(() => {
      /** Получаем соотношение сторон */
      if (imageRef.current?.naturalWidth && imageRef.current?.naturalHeight)
        setAspectRatio(imageRef.current.naturalWidth / imageRef.current.naturalHeight)
    }, [slideId, isLoading])

    const isRightAngle = (rotation?: number) => rotation === RIGHT_ANGLE || rotation === RIGHT_ANGLE_X_3
    useEffect(() => {
      if (aspectRatio && imageRef.current?.naturalWidth && maxHeight > 0) {
        if (isRightAngle(rotation)) {
          const isMoreMax = backGroundSizes[0] * aspectRatio > maxHeight
          /** Если превышена максимальная высота, выставляем максимальные размеры */
          setBackGroundSizes([
            isMoreMax ? maxHeight / aspectRatio : backGroundSizes[0],
            isMoreMax ? maxHeight : backGroundSizes[0] * aspectRatio,
          ])
        } else {
          setBackGroundSizes([backGroundSizes[0], backGroundSizes[0] / aspectRatio])
        }
      }
    }, [rotation, aspectRatio, slideId, maxHeight])

    return (
      <StyledWrap
        rotation={rotation}
        style={{
          height: aspectRatio && isRightAngle(rotation) ? backGroundSizes[0] : '100%',
          transform: `rotateZ(${rotation ? rotation : 0}deg)`,
          width: aspectRatio && isRightAngle(rotation) ? 'auto' : backGroundSizes[0],
        }}
      >
        <StyledImage
          ref={imageRef}
          src={glassPreviewSrc}
          id={glassPreviewSrc}
          onLoad={() => setIsLoading(false)}
          loading={'eager'}
          style={{
            display: isLoading ? 'none' : 'block',
            height: aspectRatio && isRightAngle(rotation) ? backGroundSizes[0] : '100%',
            width: aspectRatio && isRightAngle(rotation) ? 'auto' : backGroundSizes[0],
          }}
        />
      </StyledWrap>
    )
  },
)
