import { checkIntegration } from 'entities/lis-integration'
import { getFieldLabelBylisMode } from 'entities/lis-integration/lib/checkIntegration'
import { usePatientCasesQuery } from 'features/patients/api/query'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getDiagnosticProcedureLocalize, getIcd10Localized, getLocalizedSiteName } from 'shared/lib/common'
import { formatDateForDisplay } from 'shared/lib/date'
import { Descriptions, DescriptionsItem } from 'shared/ui/description'
import { TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'
import { AllCasesToolPanel } from 'viewer/tools/ui/all-cases/AllCasesToolPanel'

type Props = {
  caseRecord: ICase
  isLoading: boolean
  isViewer?: boolean
}

const ButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 18px;
  margin-bottom: 10px;
`
const StyledButton = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-purple-light);
  margin-right: 4px;
`

const StyledCountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  height: 16px;
  color: white;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  border-radius: 8px;
  background: var(--color-purple);
  padding: 2px 4px;
`

const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-item-container {
    display: flex;
    flex-direction: column;
  }
`

const CaseInfo = ({ caseRecord, isViewer }: Props) => {
  const [allCasesOpened, setAllCasesOpened] = useState<boolean>(false)
  const query = usePatientCasesQuery(caseRecord?.patient?.patientProfileId)
  const localOriginPath = localStorage.getItem('ORIGIN_CASE_PATH')
  const { t } = useTranslation()
  const lisMode = useLisMode()
  const { casePathologicalInfo } = caseRecord
  const icd10 = getIcd10Localized(casePathologicalInfo?.incomingIcd10)
  const diagnosticProcedure = getDiagnosticProcedureLocalize(casePathologicalInfo?.diagnosticProcedureType)

  const onOpenAllCasesPanel = () => {
    setAllCasesOpened(!allCasesOpened)
    if (localOriginPath) localStorage.removeItem('ORIGIN_CASE_PATH')
  }

  useEffect(() => {
    if (localOriginPath) setAllCasesOpened(true)
  }, [])

  return (
    <>
      {/* Имя случая */}
      {isViewer && checkIntegration('caseInfo', 'name', lisMode) && (
        <TitleElement level={3} style={{ marginBottom: 8 }}>
          {caseRecord?.name || t('Не указано')}
        </TitleElement>
      )}
      {isViewer && query?.data !== undefined && query?.data.length > 1 && (
        <ButtonWrapper onClick={onOpenAllCasesPanel}>
          <StyledButton>{t('Все случаи пациента')}</StyledButton>
          <StyledCountWrapper>{query?.data?.length}</StyledCountWrapper>
          {allCasesOpened && <AllCasesToolPanel caseRecords={query?.data} />}
        </ButtonWrapper>
      )}
      <StyledDescriptions colon={false}>
        {/* Клинические сведения */}
        {checkIntegration('caseInfo', 'clinicalInfo', lisMode) && casePathologicalInfo?.clinicalInfo && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Клинические сведения')} span={3}>
            {casePathologicalInfo.clinicalInfo}
          </DescriptionsItem>
        )}
        {/* Макроописание */}
        {checkIntegration('caseInfo', 'macroDescription', lisMode) && casePathologicalInfo?.macroDescription && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Макроописание')} span={3}>
            {casePathologicalInfo.macroDescription}
          </DescriptionsItem>
        )}
        {/* Доп. клинические сведения */}
        {checkIntegration('caseInfo', 'additionalInfo', lisMode) && casePathologicalInfo?.additionalInfo && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Доп. клинические сведения')} span={3}>
            {casePathologicalInfo?.additionalInfo}
          </DescriptionsItem>
        )}
        {/* Номер заказа */}
        {checkIntegration('caseInfo', 'orderNumber', lisMode) && caseRecord.orderNumber && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Номер заказа')} span={3}>
            {caseRecord.orderNumber}
          </DescriptionsItem>
        )}
        {/* Отделение, направившее материал / Медицинская организация */}
        {checkIntegration('caseInfo', 'department', lisMode) && casePathologicalInfo?.department && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={getFieldLabelBylisMode('department', lisMode)} span={3}>
            {casePathologicalInfo?.department?.name}
          </DescriptionsItem>
        )}
        {/* Дата заказа / Дата исследования */}
        {checkIntegration('caseInfo', 'caseDate', lisMode) && caseRecord.caseDate && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={getFieldLabelBylisMode('caseDate', lisMode)} span={3}>
            {formatDateForDisplay(caseRecord.caseDate)}
          </DescriptionsItem>
        )}
        {/* Услуга */}
        {checkIntegration('caseInfo', 'services', lisMode) && caseRecord.services && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Услуга')} span={3}>
            {caseRecord.services?.map((item, index) => {
              const servicesLength = caseRecord.services?.length || 0
              const isLast = index === servicesLength - 1
              return <div key={item.code || item.name}>{`${item.name}${isLast === false ? `, ` : ''}`}</div>
            })}
          </DescriptionsItem>
        )}
        {/* МКБ-10 / Диагноз направления */}
        {checkIntegration('caseInfo', 'incomingIcd10', lisMode) && icd10 && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={getFieldLabelBylisMode('incomingIcd10', lisMode)}
            span={3}
          >
            {icd10.code} {icd10.name}
          </DescriptionsItem>
        )}
        {/* Дата забора материала / Дата забора */}
        {checkIntegration(
          'caseInfo',
          'materialTakeoutDate',
          lisMode,
          lisMode === 'DZM' ? caseRecord.fieldSet === 'DEFAULT' : true,
        ) &&
          caseRecord.materialTakeoutDate && (
            <DescriptionsItem
              style={{ paddingBottom: 8 }}
              label={getFieldLabelBylisMode('materialTakeoutDate', lisMode)}
              span={3}
            >
              {formatDateForDisplay(caseRecord.materialTakeoutDate)}
            </DescriptionsItem>
          )}
        {/* Диагноз основного заболевания / Клинические сведения */}
        {checkIntegration('caseInfo', 'clinicalInfo', lisMode) && isViewer && casePathologicalInfo?.clinicalInfo && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={getFieldLabelBylisMode('clinicalInfo', lisMode)}
            span={3}
          >
            {casePathologicalInfo?.clinicalInfo}
          </DescriptionsItem>
        )}
        {/* Макроописание */}
        {caseRecord?.casePathologicalInfo?.macroDescription && isViewer && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label="Макроописание" span={3}>
            {caseRecord?.casePathologicalInfo?.macroDescription}
          </DescriptionsItem>
        )}
        {/* Тип материала */}
        {checkIntegration('caseInfo', 'materialType', lisMode) && casePathologicalInfo?.materialType && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Тип материала')} span={3}>
            {casePathologicalInfo.materialType?.name}
          </DescriptionsItem>
        )}
        {/* Задача прижизненного патологоанатомического исследования */}
        {checkIntegration('caseInfo', 'task', lisMode) && caseRecord.casePathologicalInfo?.task && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={t('Задача прижизненного патологоанатомического исследования')}
            span={3}
          >
            {casePathologicalInfo.task}
          </DescriptionsItem>
        )}
        {/* Способ получения материала / Способ получения */}
        {checkIntegration(
          'caseInfo',
          'diagnosticProcedureType',
          lisMode,
          lisMode === 'DZM' ? caseRecord.fieldSet === 'DEFAULT' : true,
        ) &&
          diagnosticProcedure && (
            <DescriptionsItem
              style={{ paddingBottom: 8 }}
              label={getFieldLabelBylisMode('diagnosticProcedureType', lisMode)}
              span={3}
            >
              {diagnosticProcedure?.name}
            </DescriptionsItem>
          )}
        {/* Тип исследованиия */}
        {checkIntegration('caseInfo', 'caseResearchType', lisMode) && casePathologicalInfo?.caseResearchType && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Тип исследования')} span={3}>
            {caseRecord.casePathologicalInfo.caseResearchType?.name}
          </DescriptionsItem>
        )}
        {/* Локализация / Биоматериалы */}
        {checkIntegration('caseInfo', 'sites', lisMode) &&
          casePathologicalInfo?.sites &&
          casePathologicalInfo?.sites?.length > 0 && (
            <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Локализация')} span={3}>
              {casePathologicalInfo.sites?.map((item) => (item.site ? getLocalizedSiteName(item.site) : ''))}
            </DescriptionsItem>
          )}
      </StyledDescriptions>
    </>
  )
}

export default CaseInfo
