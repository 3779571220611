import { useTypedSelector } from 'app/redux/lib/selector'
import { IAnnotationQuery, useAnnotationsQuery, useChangeAnnotationMutation } from 'features/annotations/api/query'
import { checkLocalAnnotation, checkLocalAnnotationFromFeature } from 'features/annotations/lib/helpers'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import { useUserStatusContext } from 'features/multiplayer/lib'
import { notices } from 'features/notices'
import { Feature } from 'ol'
import GeoJSON from 'ol/format/GeoJSON'
import { Geometry } from 'ol/geom'
import Select, { SelectEvent } from 'ol/interaction/Select'
import { useViewerIdSlideState, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { selectTasksViewerUrlTaskId, SideRightPanelType, viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { usePrevious } from 'shared/lib/hooks'
import { MAX_OPACITY } from 'shared/ui/tool-opacity-controller'
import { IAnnotation } from 'types/IAnnotations'
import { IMapOl } from 'types/IMapOl'
import { IMarkupClass } from 'types/IMarkupSlide'
import { IMarkupTask } from 'types/IMarkupTask'
import TViewerId from 'types/TViewerId'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useViewerDispatch, viewerSlice } from 'viewer/container'
import { INTERNAL_TYPE_ID, LayerType } from 'viewer/map'
import { getPolygonArea } from 'viewer/map/layers/annotations/lib/annotationsDrawHelpers'
import { isObjectsCounting, setSlidePositioningForAnnotation } from 'viewer/map/layers/annotations/lib/helpers'
import { getCustomClass, setDefaultStyle, styleByAnnotationClass } from 'viewer/map/layers/olStyles'
import { isFeaturesIncludeArrowOrRuler, isFeaturesIncludePolygon } from 'viewer/map/lib/utils'

import ChangeInteraction from './ChangeInteraction'
import TransformInteraction from './TransformInteraction'

const ANIMATION_DURATION = 500
const VIEW_PADDING = [250, 250, 250, 250]

type Props = {
  /** Экземпляр olMap карты */
  map: IMapOl
  /** Массив выделенных аннотаций */
  selectedAnnotationsIds: number[]
  /** Id вьювера */
  viewerId: TViewerId
  /** Разрешение слайда */
  mppX: number
  /** Массив Feature аннотаций */
  features: Feature<Geometry>[]
}

const AnnotationSelectInteraction = ({ features, map, mppX, selectedAnnotationsIds, viewerId }: Props) => {
  const [selectedFeatures, setSelectedFeatures] = useState<Feature<any>[]>([])
  const prevAnnotationsId = usePrevious<number[]>(selectedAnnotationsIds)
  const viewerDispatch = useViewerDispatch(viewerId)
  const dispatch = useDispatch()
  const { caseId, slideId } = useViewerIdSlideState(viewerId)
  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const queryClient = useQueryClient()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const currTaskClasses = queryClient.getQueryData<IMarkupClass[]>([QUERY_TYPE.TASKS_CLASSES, taskId])
  const isMulti = useTypedSelector((state) => state.viewers[viewerId].viewer.isMulti)
  const { annotationsOpacity, clickCondition, selectedFromList } = useTypedSelector((state) => state.annotations)
  const { extendedContextMenuPosition } = useTypedSelector((state) => state.annotations)
  const { isFetching } = useAnnotationsQuery(caseId, slideId)
  const [lastRemoteAnnotation, setLastRemoteAnnotation] = useState<IAnnotation>()
  const annotationsIds = queryClient.getQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }])
  const ids = annotationsIds?.ids || []
  const { isFastTravel } = useViewerPageProvided()
  const hoveredAnnotationId = useTypedSelector((state) => state.annotations.hoveredAnnotationId)
  const taskData = queryClient.getQueryData<IMarkupTask>([QUERY_TYPE.TASKS, taskId])
  const userData = taskData?.participants?.find((item) => item.userId === currentUserId)
  const isValidateRole = userData?.access === 'VALIDATE'
  const isReadOnly = taskData?.status === 'PAUSED'
  const { t } = useTranslation()
  const tolerance = useTypedSelector((state) => state.viewerPage.geometryTolerance)

  const { targetUserId, unsubscribeFromUser } = useUserStatusContext()

  // Обработка ошибки при изменении аннотации
  const onAnnotationChangeError = () => {
    queryClient.setQueryData([QUERY_TYPE.ANNOTATION, lastRemoteAnnotation?.slideAnnotationId], lastRemoteAnnotation)
    queryClient.setQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }], {
      date: new Date(),
      ids,
    })
    notices.error({
      message: t('Ошибка при изменении аннотации'),
    })
  }
  const { isLoading, mutateAsync: editAnnotation } = useChangeAnnotationMutation(
    {
      caseId,
      slideId,
    },
    {
      onError: onAnnotationChangeError,
    },
  )

  useEffect(() => {
    dispatch(annotationsSlice.actions.setAnnotationsLoading(isLoading))
  }, [isLoading])

  const selectAnnotations = useRef<Select>(
    new Select({
      condition: (evt) => {
        const condition = evt.type
        if (condition === 'singleclick') {
          dispatch(annotationsSlice.actions.setClickCondition('singleClick'))
        } else if (condition === 'dblclick') {
          dispatch(annotationsSlice.actions.setClickCondition('dbClick'))
        }
        return condition === 'singleclick' || condition === 'dblclick'
      },
      hitTolerance: 20,
      layers: (layer) => layer?.get(INTERNAL_TYPE_ID) === LayerType.ANNOTATIONS,
      style: (feature) =>
        styleByAnnotationClass({
          annotationClass: feature.get('class'),
          annotationType: feature.get('annotation_type'),
          customClass: getCustomClass(feature.get('class'), feature.get('annotation_type'), currTaskClasses),
          isSelect: selectedAnnotationsIds.includes(feature.get('slideAnnotationId')),
          isTask: !!taskId,
          opacity: annotationsOpacity / MAX_OPACITY,
        }),
    }),
  )
  useEffect(() => {
    if (isFastTravel) return
    selectAnnotations.current?.on('select', (e: SelectEvent) => {
      unsubscribeFromUser()
      const selectAnnotation = e.selected[0]
      dispatch(annotationsSlice.actions.setSelectedFromList(false))
      if (!selectAnnotation || (selectAnnotation?.get('userId') !== currentUserId && !isValidateRole)) {
        dispatch(annotationsSlice.actions.setClickCondition('default'))
        viewerDispatch(viewerSlice.actions.setSelectedAnnotationsIds([]))
        return
      }
      if (selectAnnotation?.get('slideAnnotationId')) {
        const slideAnnotationId = +selectAnnotation?.get('slideAnnotationId')
        const type = selectAnnotation?.get('annotation_type')
        if (isObjectsCounting(type)) {
          viewerDispatch(viewerSlice.actions.selectObjectId(slideAnnotationId))
          dispatch(viewerPageSlice.actions.openObjectsCounting())
          dispatch(viewerPageSlice.actions.setCountingObjectType(type))
          return
        }
        viewerDispatch(viewerSlice.actions.selectAnnotations(slideAnnotationId))
        dispatch(viewerPageSlice.actions.setViewerRightPanel(SideRightPanelType.ANNOTATIONS))
      }
    })
    /** Измененные аннотации сохраняются */
    selectedFeatures.forEach((feature) => {
      feature?.get('changed') && saveFeature(feature)
    })
    map.addInteraction(selectAnnotations.current)
    return () => {
      map.removeInteraction(selectAnnotations.current)
    }
  }, [slideId, isFastTravel, targetUserId])

  useEffect(() => {
    if (targetUserId) {
      selectAnnotations.current.getFeatures().clear()
      setSelectedFeatures([])
    } else {
      const selFeatures = features.filter((item) => selectedAnnotationsIds.includes(item.get('slideAnnotationId')))
      setSelectedFeatures(selFeatures)
      if (selFeatures.length) {
        selectAnnotations.current.getFeatures().clear()
        selFeatures.forEach((feature) => {
          setDefaultStyle(!!taskId, annotationsOpacity / MAX_OPACITY, feature, currTaskClasses)
          selectAnnotations.current.getFeatures().push(feature)
        })
      }
    }
  }, [targetUserId])

  //ClickCondition change handler from selected annotation
  useDeepCompareEffect(() => {
    if (selectedFeatures?.find((it) => it.get('userId') !== currentUserId && !isValidateRole)) {
      dispatch(annotationsSlice.actions.setClickCondition('default'))
    } else if (isFeaturesIncludeArrowOrRuler(selectedFeatures)) {
      dispatch(annotationsSlice.actions.setClickCondition('dbClick'))
    } else if (clickCondition !== 'dbClick' || selectedFromList) {
      dispatch(annotationsSlice.actions.setClickCondition('singleClick'))
    }
  }, [selectedFeatures])

  //Эффекты для сохранения аннотации после редактирования внутри ChangeInteraction

  useDeepCompareEffect(() => {
    if (clickCondition === 'dbClick') return
    const lastFeatures = features.filter((item) => prevAnnotationsId?.includes(item.get('slideAnnotationId')))
    lastFeatures.forEach((feature) => {
      feature?.setStyle(
        styleByAnnotationClass({
          annotationClass: feature.get('class'),
          annotationType: feature.get('annotation_type'),
          customClass: getCustomClass(feature.get('class'), feature.get('annotation_type'), currTaskClasses),
          geometry: feature.getGeometry(),
          isSelect: selectedAnnotationsIds.includes(feature.get('slideAnnotationId')),
          isTask: !!taskId,
          opacity: annotationsOpacity / MAX_OPACITY,
        }),
      )
      feature?.get('changed') && saveFeature(feature)
    })
  }, [selectedAnnotationsIds, extendedContextMenuPosition])

  useDeepCompareEffect(() => {
    /** Очищаем выбранные feature, если нет выбранных аннотаций в стейте */
    if (!selectedAnnotationsIds.length) {
      selectAnnotations.current.getFeatures().clear()
      setSelectedFeatures([])
      return
    }

    const selFeatures = features.filter(
      (item) =>
        !checkLocalAnnotationFromFeature(item) && selectedAnnotationsIds.includes(item.get('slideAnnotationId')),
    )
    setSelectedFeatures(selFeatures)
    if (selFeatures.length) {
      selectAnnotations.current.getFeatures().clear()
      selFeatures.forEach((feature) => {
        setDefaultStyle(!!taskId, annotationsOpacity / MAX_OPACITY, feature, currTaskClasses)
        selectAnnotations.current.getFeatures().push(feature)
      })
    }
    setTimeout(() => {
      const selFeatures = features.filter((item) => selectedAnnotationsIds.includes(item.get('slideAnnotationId')))
      if (selFeatures.length && hoveredAnnotationId === selFeatures[0].get('slideAnnotationId')) {
        /** Перемещение к выбранной аннотации */
        if (selFeatures.length === 1 && selectedFromList && !isMulti) {
          const geometry = selFeatures[0].getGeometry()
          if (geometry) {
            unsubscribeFromUser()

            const annotationExtent = geometry.getExtent()
            const slideCenter = selFeatures[0].get('slideCenter')
            const slideResolution = selFeatures[0].get('slideResolution')
            const slideRotation = selFeatures[0].get('slideRotation')
            if (map.getView().getAnimating()) {
              map.getView().cancelAnimations()
            }
            viewerDispatch(viewerSlice.actions.setIsAnimatingIntoAnnotation(true))
            slideCenter === undefined || slideResolution === undefined || slideRotation === undefined
              ? /** При отсутствии параметра центрируем поле зрения на аннотации  */
                map.getView().fit(annotationExtent, {
                  duration: ANIMATION_DURATION,
                  padding: VIEW_PADDING,
                })
              : map.getView().animate({
                  center: slideCenter,
                  duration: ANIMATION_DURATION,
                  resolution: slideResolution,
                  rotation: slideRotation,
                })
            setTimeout(() => {
              viewerDispatch(viewerSlice.actions.setIsAnimatingIntoAnnotation(false))
            }, ANIMATION_DURATION)
          }
        }
      }
    }, 400)
  }, [features, selectedAnnotationsIds, selectedFromList])

  const saveFeature = useCallback((lastFeature: Feature<any>) => {
    const slideAnnotationId = lastFeature?.get('slideAnnotationId')

    if (!slideAnnotationId) {
      return
    }
    dispatch(annotationsSlice.actions.setSelectedFromList(false))
    const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, slideAnnotationId])
    if (annotation && checkLocalAnnotation(annotation)) return
    setLastRemoteAnnotation(annotation)
    let metric = 0
    const geom = lastFeature.getGeometry()
    if (geom) {
      metric = getPolygonArea(geom, mppX)
    }

    setSlidePositioningForAnnotation(lastFeature, map.getView())

    const data = {
      formattedFeature: new GeoJSON().writeFeature(lastFeature),
      type: 'ANNOTATION',
    }
    //TODO запитизировать annotation.annotation
    //@ts-ignore
    const type = annotation?.type || annotation.annotation.properties.annotation_type
    const annotationSlideId: number = lastFeature?.get('slideId') || undefined
    const editableAnnotation = {
      ...annotation,
      annotationSlideId,
      data,
      metric,
      type,
    }
    queryClient.setQueryData([QUERY_TYPE.ANNOTATION, slideAnnotationId], editableAnnotation)

    editAnnotation({
      ...editableAnnotation,
      slideAnnotationId,
    })
  }, [])

  /** Доступность селекта аннотации в зависимости от пользователя  */
  const isTransformAvailable = useMemo(
    () =>
      !selectedAnnotationsIds.find((it) => {
        const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, it])
        return annotation?.userId !== currentUserId && !isValidateRole
      }),
    [selectedAnnotationsIds],
  )

  // Скрыто в рамках ONECELL-4719
  // /** Эффект, применяющий симплификацию */
  // useDeepCompareEffect(() => {
  //   if (!selectedFeatures) return
  //   if (tolerance !== undefined && selectedFeatures?.length > 0) {
  //     const feature = selectedFeatures[0]
  //     const slideAnnotationId = feature?.get('slideAnnotationId')
  //     const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, slideAnnotationId])
  //     if (![AnnotationType.PEN, AnnotationType.POLYGON].includes(annotation?.type as AnnotationType)) return
  //     if (!annotation) return
  //     const newGeometry = simplifyOperation(tolerance, annotation)
  //     feature.setGeometry(newGeometry)
  //     /** Если tolerance был сброшен на 0, сохранения аннотации не произойдёт **/
  //     feature.set('changed', !!tolerance)
  //   }
  // }, [selectedFeatures, tolerance])
  return (
    <>
      {selectedAnnotationsIds?.length > 0 &&
        !isReadOnly &&
        clickCondition === 'singleClick' &&
        !isFeaturesIncludeArrowOrRuler(selectedFeatures) && (
          <TransformInteraction
            map={map}
            features={selectedFeatures?.filter((it) => isValidateRole || it.get('userId') === currentUserId)}
            saveFeature={saveFeature}
            isLoading={isLoading || isFetching}
            isTransformAvailable={isTransformAvailable}
            annotationsOpacity={annotationsOpacity}
          />
        )}
      {selectedAnnotationsIds?.length > 0 &&
        !isReadOnly &&
        isFeaturesIncludePolygon(selectedFeatures) &&
        clickCondition === 'dbClick' && (
          <ChangeInteraction
            map={map}
            features={selectedFeatures}
            isLoading={isLoading || isFetching}
            viewerId={viewerId}
          />
        )}
    </>
  )
}

export default AnnotationSelectInteraction
