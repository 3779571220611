import { useTypedSelector } from 'app/redux/lib/selector'
import { useIiifConfigQuery } from 'entities/slide-layers'
import IIIFInfo from 'ol/format/IIIFInfo'
import {
  useOpenViewers,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import {
  selectAtlasViewerUrlSlideId,
  selectTasksViewerUrlTaskId,
  TGridInfoTool,
  viewerPageSlice,
} from 'pages/viewer/model/viewerPageSlice'
import { memo, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MouseOverMapContext } from 'shared/lib/map'
import { LeftPanelContext } from 'shared/lib/viewer/LeftPanelContext'
import styled from 'styled-components/macro'
import IUserRole from 'types/IUserRole'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, useViewerMainSelector } from 'viewer/container'
import { viewerHelpSlice } from 'viewer/help/model/viewerHelpSlice'

import ArtefactsToolPanel from './artefacts/ArtefactsToolPanel'
import AtlasValidationToolPanel from './atlas-validation/AtlasValidationToolPanel'
import { GlassPreviewToolPanel } from './glass-preview/GlassPreviewToolPanel'
import HeatMapOpacityToolPanel from './HeatMapOpacityToolPanel'
import Ki67ToolPanel from './ki67/Ki67ToolPanel'
import { LabelInfoToolPanel } from './label-info/LabelInfoToolPanel'
import MedicalNeuronetsToolPanel from './medicalNeuronets/MedicalNeuronetsToolPanel'
import SearchMorphologyTool from './morphology/SearchMorphologyTool'
import ObjectsToolPanel from './objects-counting/ObjectsToolPanel'
import OverviewToolPanel from './overview/OverviewToolPanel'
import PathVisionSegmentationToolPanel from './path-vision-segmentation/PathVisionSegmentationToolPanel'
import SegmentationToolPanel from './segmentation/SegmentationToolPanel'
import SlideInfoToolPanel from './slide-info/SlideInfoToolPanel'

type Props = {
  viewerId: TViewerId
  mppX: number
}

const GridWrapper = styled.div<{ panelWidth?: number }>`
  position: fixed;
  top: 48px;
  left: ${({ panelWidth }) => (panelWidth ?? 0 + 8) + 'px'};
  bottom: 32px;
  max-height: 100%;

  padding: 8px;
  width: 1px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 8px;
  z-index: 10;
`

/** Длина массива с размерами слайда (Ширина и высота) */
const SLIDE_SIZE_LENGTH = 2

const ViewerToolsGridContainer = memo(({ viewerId }: Props) => {
  const dispatch = useDispatch()
  const { setMouseOver } = useContext(MouseOverMapContext)
  const { value } = useContext(LeftPanelContext)
  const { activeViewerId, openViewerIds } = useOpenViewers()
  const { viewerType } = useViewerPageProvided()
  const { isObjectsDrawActive } = useViewerMainSelector(viewerId)
  const viewerDispatch = useViewerDispatch(viewerId)
  const authorities = useTypedSelector((state) => state.user.authorities)
  const isAtlasAvailable = authorities?.includes(IUserRole.ROLE_ATLAS_VALIDATION)
  const { hideHelpMessage } = viewerHelpSlice?.actions || {}

  const { caseId, slideId, source, viewerMode } = useViewerIdSlideState(viewerId)
  const {
    ARTEFACTS,
    GLASS_PREVIEW,
    HEAT_MAP,
    KI67,
    MEDICALNEURONETS_CRC,
    MITOSIS,
    OVERVIEW,
    PV_PROSTATE,
    SEARCH_MORPHOLOGY,
    SEGMENTATION,
    SLIDE_INFO,
    SLIDE_LABEL,
    VALIDATION,
  } = useTypedSelector((state) => state.viewerPage.tools)

  const atlasSlideId = useSelector(selectAtlasViewerUrlSlideId)
  const taskSlideId = useSelector(selectTasksViewerUrlTaskId)
  const type = atlasSlideId !== undefined ? 'ATLAS' : taskSlideId ? 'TASK' : 'DEFAULT'

  const overviewRef = useRef<HTMLDivElement>(null)
  const slideInfoRef = useRef<HTMLDivElement>(null)
  const slideLabelRef = useRef<HTMLDivElement>(null)
  const glassPreviewRef = useRef<HTMLDivElement>(null)
  const segmentationRef = useRef<HTMLDivElement>(null)
  const pathVisionSegmentationRef = useRef<HTMLDivElement>(null)
  const validationRef = useRef<HTMLDivElement>(null)
  const mitosisRef = useRef<HTMLDivElement>(null)
  const heatMapRef = useRef<HTMLDivElement>(null)
  const ki67Ref = useRef<HTMLDivElement>(null)
  const searchMorphologyRef = useRef<HTMLDivElement>(null)
  const artefactsRef = useRef<HTMLDivElement>(null)
  const medicalNeuronetsRef = useRef<HTMLDivElement>(null)

  const blockFlexis = useMemo(() => {
    const refs = [
      overviewRef,
      slideLabelRef,
      glassPreviewRef,
      slideInfoRef,
      segmentationRef,
      validationRef,
      mitosisRef,
      heatMapRef,
      ki67Ref,
      searchMorphologyRef,
      artefactsRef,
      medicalNeuronetsRef,
    ]
    const positions = [
      OVERVIEW,
      SLIDE_LABEL,
      GLASS_PREVIEW,
      SLIDE_INFO,
      SEGMENTATION,
      VALIDATION,
      MITOSIS,
      HEAT_MAP,
      KI67,
      SEARCH_MORPHOLOGY,
      ARTEFACTS,
      MEDICALNEURONETS_CRC,
    ]
    const results = []
    for (let i = 0; i < refs.length; i++) {
      if (refs[i].current !== null) {
        // @ts-ignore
        const position = window.getComputedStyle(refs[i].current).position
        if (position === 'relative') {
          results.push({
            position: positions[i].position,

            // @ts-ignore
            x: refs[i].current.getBoundingClientRect().x + refs[i].current.offsetWidth,
            // @ts-ignore
            y: refs[i].current.getBoundingClientRect().y + refs[i].current.offsetHeight,
          })
        }
      }
    }
    return results
  }, [
    OVERVIEW,
    SLIDE_LABEL,
    GLASS_PREVIEW,
    SLIDE_INFO,
    SEGMENTATION,
    VALIDATION,
    MITOSIS,
    HEAT_MAP,
    KI67,
    SEARCH_MORPHOLOGY,
    ARTEFACTS,
    MEDICALNEURONETS_CRC,
  ])

  useEffect(() => {
    if (blockFlexis === undefined) return
    dispatch(viewerPageSlice.actions.setPanelInFlex(blockFlexis))
  }, [blockFlexis])

  useEffect(() => {
    if (!isObjectsDrawActive) viewerDispatch(hideHelpMessage(false))
  }, [isObjectsDrawActive])

  useEffect(() => {
    if (!VALIDATION.isVisible && isAtlasAvailable) {
      viewerDispatch(viewerPageSlice.actions.toggleTool('VALIDATION'))
    }
  }, [])

  const { data: iiifInfo } = useIiifConfigQuery(slideId)
  const iiifTileSourceOptions = iiifInfo ? new IIIFInfo(iiifInfo).getTileSourceOptions() : null
  const size =
    iiifTileSourceOptions && iiifTileSourceOptions.size.length === SLIDE_SIZE_LENGTH
      ? (iiifTileSourceOptions.size as [number, number])
      : undefined

  const saveInfoToolPanelPosition = useCallback(
    (tool: TGridInfoTool) => (position: number[]) => {
      dispatch(viewerPageSlice.actions.setToolPosition({ position, tool }))
    },
    [dispatch],
  )

  return (
    <GridWrapper onMouseEnter={() => setMouseOver(false)} onMouseLeave={() => setMouseOver(true)} panelWidth={value}>
      <OverviewToolPanel
        overviewRef={overviewRef}
        viewerId={viewerId}
        slideId={slideId}
        caseId={caseId}
        initPosition={OVERVIEW?.position}
        onPositionChange={saveInfoToolPanelPosition('OVERVIEW')}
        isVisible={OVERVIEW?.isVisible && viewerId === activeViewerId}
      />
      {GLASS_PREVIEW?.isVisible && viewerId === activeViewerId && (
        <GlassPreviewToolPanel
          slideLabelRef={glassPreviewRef}
          slideId={slideId}
          caseId={caseId}
          viewerId={viewerId}
          source={source}
          initPosition={GLASS_PREVIEW?.position}
          onPositionChange={saveInfoToolPanelPosition('GLASS_PREVIEW')}
        />
      )}
      {viewerType === 'ATLAS' && VALIDATION?.isVisible && viewerMode === 'DEFAULT' && viewerId === activeViewerId && (
        <AtlasValidationToolPanel
          validationRef={validationRef}
          viewerId={viewerId}
          initPosition={VALIDATION?.position}
          onPositionChange={saveInfoToolPanelPosition('VALIDATION')}
        />
      )}
      {HEAT_MAP?.isVisible && viewerId === activeViewerId && (
        <HeatMapOpacityToolPanel
          heatMapRef={heatMapRef}
          HEAT_MAP={HEAT_MAP}
          viewerId={viewerId}
          initPosition={HEAT_MAP?.position}
          onPositionChange={saveInfoToolPanelPosition('HEAT_MAP')}
        />
      )}
      {SLIDE_LABEL?.isVisible && viewerId === activeViewerId && (
        <LabelInfoToolPanel
          slideLabelRef={slideLabelRef}
          slideId={slideId}
          caseId={caseId}
          viewerId={viewerId}
          source={source}
          initPosition={SLIDE_LABEL?.position}
          onPositionChange={saveInfoToolPanelPosition('SLIDE_LABEL')}
        />
      )}
      {SLIDE_INFO?.isVisible && viewerId === activeViewerId && (
        <SlideInfoToolPanel
          slideInfoRef={slideInfoRef}
          viewerId={viewerId}
          size={size}
          slideId={slideId}
          caseId={caseId}
          source={source}
          initPosition={SLIDE_INFO?.position}
          onPositionChange={saveInfoToolPanelPosition('SLIDE_INFO')}
        />
      )}
      {SEGMENTATION?.isVisible && viewerId === activeViewerId && (
        <SegmentationToolPanel
          segmentationRef={segmentationRef}
          slideId={slideId}
          caseId={caseId}
          viewerId={viewerId}
          source={source}
          initPosition={SEGMENTATION?.position}
          onPositionChange={saveInfoToolPanelPosition('SEGMENTATION')}
        />
      )}
      {KI67?.isVisible && viewerId === activeViewerId && type !== 'ATLAS' && (
        <Ki67ToolPanel
          ki67Ref={ki67Ref}
          viewerId={viewerId}
          initPosition={KI67?.position}
          onPositionChange={saveInfoToolPanelPosition('KI67')}
        />
      )}
      {MITOSIS?.isVisible && viewerId === activeViewerId && type !== 'ATLAS' && (
        <ObjectsToolPanel
          mitosisRef={mitosisRef}
          viewerId={viewerId}
          initPosition={MITOSIS?.position}
          onPositionChange={saveInfoToolPanelPosition('MITOSIS')}
        />
      )}
      {SEARCH_MORPHOLOGY?.isVisible && viewerId === activeViewerId && (
        <SearchMorphologyTool
          searchMorphologyRef={searchMorphologyRef}
          initPosition={SEARCH_MORPHOLOGY?.position}
          onPositionChange={saveInfoToolPanelPosition('SEARCH_MORPHOLOGY')}
        />
      )}
      {PV_PROSTATE.isVisible && viewerId === activeViewerId && openViewerIds.length === 1 && (
        <PathVisionSegmentationToolPanel
          pathVisionSegmentationRef={pathVisionSegmentationRef}
          initPosition={PV_PROSTATE?.position}
          onPositionChange={saveInfoToolPanelPosition('PV_PROSTATE')}
        />
      )}
      {ARTEFACTS?.isVisible && viewerId === activeViewerId && openViewerIds.length === 1 && (
        <ArtefactsToolPanel
          artefactsRef={artefactsRef}
          initPosition={ARTEFACTS?.position}
          onPositionChange={saveInfoToolPanelPosition('ARTEFACTS')}
        />
      )}
      {MEDICALNEURONETS_CRC?.isVisible && viewerId === activeViewerId && openViewerIds.length === 1 && (
        <MedicalNeuronetsToolPanel
          ref={medicalNeuronetsRef}
          initPosition={MEDICALNEURONETS_CRC?.position}
          onPositionChange={saveInfoToolPanelPosition('MEDICALNEURONETS_CRC')}
        />
      )}
    </GridWrapper>
  )
})

export default ViewerToolsGridContainer
