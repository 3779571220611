import { useTypedSelector } from 'app/redux/lib/selector'
import { useTaskClasses } from 'entities/tasks/api/query'
import { annotationsSlice, useAddAnnotationsMutation, useChangeAnnotationMutation } from 'features/annotations'
import {
  deleteAnnotationsMutation,
  IAnnotationQuery,
  QueryFlags,
  updateAnnotationsQuery,
} from 'features/annotations/api/query'
import { notices } from 'features/notices'
import { Feature } from 'ol'
import { MultiPolygon, Polygon } from 'ol/geom'
import {
  useOpenViewers,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import { selectTasksViewerUrlTaskId } from 'pages/viewer/model/viewerPageSlice'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { useOS } from 'shared/lib/hooks'
import { MapContext } from 'shared/lib/map'
import { MAX_OPACITY } from 'shared/ui/tool-opacity-controller'
import { AnnotationType, IAnnotation, IAnnotationStack, ISlideAnnotation } from 'types/IAnnotations'
import { IMarkupTask } from 'types/IMarkupTask'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'
import { viewerHelpSlice } from 'viewer/help'
import {
  completedMitosisSpotStyle,
  defaultStyle,
  getCustomClass,
  styleByAnnotationClass,
} from 'viewer/map/layers/olStyles'
import { getCoordinatesLength, getFeaturesFromGeoJson, isPolygonAnnotation } from 'viewer/map/lib/utils'

import AnnotationDescriptionLayer from './AnnotationDescriptionLayer'
import { MIN_POLYGON_COORDINATES } from './lib/annotationsDrawHelpers'
import { isObjectsCounting } from './lib/helpers'
import AnnotationDrawInteraction from './lib/interactions/AnnotationDrawInteraction'
import { CONTEXT_MENU_HEIGHT, CONTEXT_MENU_WIDTH, LiteAnnotationContextMenu } from './ui/LiteAnnotationContextMenu'
import VectorAnnotationsLayerContainer from './VectorAnnotationsLayerContainer'

type AnnotationsLayerProps = {
  annotations: IAnnotation[]
  mppX: number
}

const AnnotationsLayer = ({ annotations, mppX }: AnnotationsLayerProps) => {
  const queryClient = useQueryClient()
  const { map, viewerId } = useContext(MapContext)
  const { activeViewerId } = useOpenViewers()
  const { caseId, slideId } = useViewerIdSlideState(viewerId)
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const viewerDispatch = useViewerDispatch(viewerId)
  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const { annotationType, annotationsIsVisible, annotationsOpacity, prevAnnotationsOpacity } = useTypedSelector(
    (state) => state.annotations,
  )
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const { liteContextMenuVisibility } = useTypedSelector((state) => state.viewerPage)
  const currentStack = queryClient.getQueryData<IAnnotationStack[]>([QUERY_TYPE.ANNOTATIONS_STACK, slideId])
  const { mutateAsync: addAnnotations } = useAddAnnotationsMutation({ caseId, slideId })
  const [annotationMenuPosition, setAnnotationMenuPosition] = useState<number[] | null>(null)
  const [newAnnotationId, setNewAnnotationId] = useState<number | undefined>()
  const task = queryClient.getQueryData<IMarkupTask>([QUERY_TYPE.TASKS, taskId])
  const isPausedTask = task?.status === 'PAUSED'
  const annotationsIds = queryClient.getQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }])
  const ids = annotationsIds?.ids || []
  const { t } = useTranslation()

  const { data: currTaskClasses } = useTaskClasses(taskId)
  // Обработка ошибки при удалении аннотации
  const onAnnotationDeleteError = () => {
    const caseData = queryClient.getQueryData<ISlideAnnotation[]>([QUERY_TYPE.ANNOTATION, { caseId }])
    const currentAnnotations = caseData?.find((item) => item.slideId === slideId)?.annotations
    const selAnnotations = annotations.filter((ann) => selectedAnnotationsIds.includes(ann.slideAnnotationId)) || []
    queryClient.setQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }], {
      date: new Date(),
      ids,
    })
    // обновляем хранилище аннотаций по кейсу (возвращаем аннотацию)
    slideId &&
      queryClient.setQueryData(
        [QUERY_TYPE.ANNOTATION, { caseId }],
        caseData?.length && caseData.find((item) => item.slideId === slideId)
          ? [
              ...caseData.map((annotationsGroup) => {
                if (annotationsGroup.slideId === slideId) {
                  return {
                    annotations: currentAnnotations ? [...currentAnnotations, ...selAnnotations] : [...selAnnotations],
                    slideId: annotationsGroup.slideId,
                  }
                }
                return annotationsGroup
              }),
            ]
          : [{ annotations: [...selAnnotations], slideId }],
      )
    notices.error({
      message: t('Ошибка при удалении аннотации'),
    })
  }
  const features = useMemo(
    () =>
      annotations.flatMap((annotation) => {
        const features = getFeaturesFromGeoJson(annotation.data?.formattedFeature)
        const mitosisBboxes = features.filter((it) => it.get('element') === 'bbox')
        const mitosisMultiPolygon = new MultiPolygon([])
        mitosisBboxes.forEach((it) => mitosisMultiPolygon.appendPolygon(it.getGeometry()))
        const feature = mitosisBboxes.length > 0 ? new Feature(mitosisMultiPolygon) : features[0]

        const isPolygon = isPolygonAnnotation(annotation.type)
        const geometry: Polygon = feature.getGeometry()
        if (isPolygon && getCoordinatesLength(geometry) <= MIN_POLYGON_COORDINATES) feature.setGeometry([])

        feature.set('slideAnnotationId', annotation.slideAnnotationId)
        feature.set('userId', annotation.userId)
        feature?.set('annotation_type', annotation.type)
        task
          ? feature.set('user', task.participants?.find((item) => item.userId === annotation.userId)?.user?.fullname)
          : feature.set('user', annotation.user)
        feature.set('changed', false)
        annotation?.caption && feature?.set('description', annotation?.caption)

        const aClass = feature.get('class')
        const isSelect = selectedAnnotationsIds.includes(feature.get('slideAnnotationId'))
        feature.setStyle(
          aClass
            ? styleByAnnotationClass({
                annotationClass: aClass,
                annotationType: annotation.type,
                customClass: getCustomClass(aClass, annotation.type, currTaskClasses),
                geometry,
                isSelect: isSelect,
                isTask: !!taskId,
                opacity: annotationsOpacity / MAX_OPACITY,
              })
            : isObjectsCounting(annotation.type)
            ? completedMitosisSpotStyle
            : defaultStyle,
        )
        feature.setId(annotation?.slideAnnotationId)
        return feature
      }),
    [annotations, slideId],
  )

  const { isLoading: isDeleteLoading, mutate: deleteAnnotations } = deleteAnnotationsMutation(
    {
      caseId,
      currentUserId,
    },
    {
      onError: onAnnotationDeleteError,
      onSuccess: () => {
        viewerDispatch(viewerSlice.actions.setSelectedAnnotationsIds([]))
      },
    },
  )
  const { mutateAsync: editAnnotation } = useChangeAnnotationMutation({
    caseId,
    slideId,
  })

  const drawEndHandler = useCallback(
    (position: number[], annotationId: number, annotationType: AnnotationType) => {
      const mapWidth = map.getSize()?.[0]
      const mapHeight = map.getSize()?.[1]
      const adjustedPosition = [
        mapWidth && position[0] + CONTEXT_MENU_WIDTH > mapWidth ? position[0] - CONTEXT_MENU_WIDTH : position[0],
        mapHeight && position[1] + CONTEXT_MENU_HEIGHT > mapHeight ? position[1] - CONTEXT_MENU_HEIGHT : position[1],
      ]
      if (liteContextMenuVisibility && annotationType !== AnnotationType.POINT) {
        setAnnotationMenuPosition(adjustedPosition)
        setNewAnnotationId(annotationId)
      }
    },
    [liteContextMenuVisibility],
  )

  useHotkeys(
    'del, backspace',
    () => {
      if (selectedAnnotationsIds?.length && !isPausedTask) {
        const deletedAnnotations: IAnnotation[] = []
        for (const id of selectedAnnotationsIds) {
          const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, id])
          annotation?.slideAnnotationId && deletedAnnotations.push(annotation)
        }
        deleteAnnotations({ annotations: deletedAnnotations })
      }
    },
    {
      enabled: selectedAnnotationsIds?.length > 0 && activeViewerId === viewerId && !isDeleteLoading,
    },
    [selectedAnnotationsIds, activeViewerId, isDeleteLoading],
  )

  useHotkeys(
    'esc',
    () => {
      viewerDispatch(viewerSlice.actions.setSelectedAnnotationsIds([]))
    },
    {
      enabled: selectedAnnotationsIds?.length > 0 && activeViewerId === viewerId,
    },
    [selectedAnnotationsIds, activeViewerId],
  )
  const { isFastTravel } = useViewerPageProvided()

  useHotkeys(
    'J',
    () => {
      if (annotationsOpacity !== MAX_OPACITY && !isFastTravel) {
        viewerDispatch(annotationsSlice.actions.setAnnotationsOpacity(Number(MAX_OPACITY)))
        viewerDispatch(annotationsSlice.actions.setPrevAnnotationsOpacity(Number(annotationsOpacity)))
      } else {
        viewerDispatch(annotationsSlice.actions.setAnnotationsOpacity(Number(prevAnnotationsOpacity)))
        viewerDispatch(annotationsSlice.actions.setPrevAnnotationsOpacity(Number(annotationsOpacity)))
      }
    },
    [annotationsOpacity, prevAnnotationsOpacity, isFastTravel],
  )

  useHotkeys(
    `${useOS() === 'MacOS' ? 'Cmd+Z' : 'Ctrl+Z'}`,
    () => {
      if (currentStack && !isFastTravel) {
        viewerDispatch(viewerHelpSlice.actions.setHelpMessage(t('Отмена последнего действия')))
        setTimeout(() => {
          viewerDispatch(viewerHelpSlice.actions.hideHelpMessage(false))
        }, 1500)

        const lastAction = currentStack?.pop()

        const lastAnnotation = Array.isArray(lastAction?.annotation)
          ? lastAction?.annotation?.pop()
          : lastAction?.annotation

        if (Array.isArray(lastAction?.annotation) && lastAction?.annotation?.length) currentStack.push(lastAction)

        if (!lastAnnotation) return

        const { caption, data, metric, slideAnnotationId, slideId, type, zindex } = lastAnnotation

        switch (lastAction?.type) {
          case 'add':
            if (!isPausedTask) {
              deleteAnnotations({
                annotations: [lastAnnotation],
                noCashing: true,
              })
              updateAnnotationsQuery({
                caseId,
                flag: QueryFlags.MULTIDEL,
                ids: [slideAnnotationId],
                queryClient,
                slideId,
              })
            }
            return
          case 'del': {
            if (data && !isPausedTask) {
              addAnnotations(
                {
                  annotations: [lastAnnotation],
                  noCashing: true,
                },
                {
                  onSuccess: () => {
                    const ids = queryClient.getQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }])
                    if (!ids?.ids.length) return
                    const newAnnotationId = ids.ids[ids.ids.length - 1]
                    const updStack = currentStack.map((item) => {
                      const annotation = Array.isArray(item.annotation) ? item.annotation.at(-1) : item.annotation

                      return annotation?.slideAnnotationId === slideAnnotationId
                        ? {
                            ...item,
                            annotation: {
                              ...item.annotation,
                              slideAnnotationId: newAnnotationId,
                            },
                          }
                        : item
                    })
                    queryClient.setQueryData([QUERY_TYPE.ANNOTATIONS_STACK, slideId], updStack)
                  },
                },
              )
              updateAnnotationsQuery({
                annotations: [lastAnnotation],
                caseId,
                flag: QueryFlags.ADD,
                ids: [slideAnnotationId],
                queryClient,
                slideId,
              })
            }
            return
          }
          case 'edit':
            if (!isPausedTask && data) {
              editAnnotation({
                caption,
                data: {
                  formattedFeature: data?.formattedFeature,
                  type: 'ANNOTATION',
                },
                metric,
                noCashing: true,
                slideAnnotationId,
                type,
                zindex,
              })
              queryClient.setQueryData([QUERY_TYPE.ANNOTATION, slideAnnotationId], lastAnnotation)
            }
            return
          default:
            return
        }
      }
    },
    [currentStack, isFastTravel, isPausedTask],
  )

  return (
    <>
      {map && viewerId === activeViewerId && (
        <VectorAnnotationsLayerContainer features={features} mppX={mppX} map={map} viewerId={viewerId} />
      )}
      {annotationMenuPosition !== null &&
        newAnnotationId &&
        viewerId === activeViewerId &&
        annotationType !== AnnotationType.POINT && (
          <LiteAnnotationContextMenu
            annotationId={newAnnotationId}
            position={annotationMenuPosition}
            viewerId={viewerId}
            mppX={mppX}
            onClose={() => {
              setNewAnnotationId(undefined)
              setAnnotationMenuPosition(null)
            }}
          />
        )}
      {map && annotationType && viewerId === activeViewerId && (
        <AnnotationDrawInteraction
          map={map}
          viewerId={viewerId}
          mppX={mppX}
          type={annotationType}
          vectorAnnotations={annotations}
          onDrawEnd={drawEndHandler}
        />
      )}
      {!taskId && viewerId === activeViewerId && annotationsIsVisible && (
        <AnnotationDescriptionLayer map={map} viewerId={viewerId} features={features} mppX={mppX} />
      )}
    </>
  )
}

export default AnnotationsLayer
