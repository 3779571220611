import { useTypedSelector } from 'app/redux/lib/selector'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { TGridInfoTool, TGridInfoToolId, viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React, { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { NON_CONTEXT_ID } from 'shared/lib/map/ui/MapProvider'
import { Draggable } from 'shared/ui/draggable'
import { ButtonElement, IconElement } from 'shared/ui/kit'
import styled, { FlattenSimpleInterpolation } from 'styled-components'
import { useViewerDispatch, viewerSlice } from 'viewer/container'
import { LayerType } from 'viewer/map'

import { RIGHT_ANGLE, RIGHT_ANGLE_X_3, STRAIGHT_ANGLE } from './lib/constans'

const IconBlock = styled.div`
  position: absolute;
  bottom: -4px;
  right: 1px;
`

/** Props for ViewerInfoToolPanelContainer component */
type Props = {
  /** id - id текущей панели */
  id?: TGridInfoToolId
  /** panelRef -  ссылка на элемент текущей панели */
  panelRef?: React.RefObject<HTMLDivElement>
  /** initPosition - начальные координаты положения текущей панели */
  initPosition: number[]
  /** onPositionChange - коллбэк для изменения позиции текущей панели */
  onPositionChange: (position: number[]) => void
  /** setSizes - коллбэк для изменения размера панели (используется для миникарты) */
  setSizes?: (sizes: number[]) => void
  /** type - тип текущей панели */
  type: TGridInfoTool
  /** footer - элемент, который будет отрисовать внизу панели */
  mapSizes?: number[]
  disableDrag?: boolean
  footer?: ReactNode
  /** onClickHandlerRotate - коллбэк для поворота содержимого панели (используется для лэйбла) */
  onClickHandlerRotate?: () => void
  /** style - дополнительные стили для текущей панели */
  style?: FlattenSimpleInterpolation
  /** текущая позиция панели */
  position: number[]
  /** коллбэк для установки текущей позиции панели */
  setPosition: (sizes: number[]) => void
  /** Заголовок панели (если должен отличаться от того, который определен в компоненте) */
  title?: string
}

const IconButton = styled(ButtonElement)`
  &.ant-btn-icon-only {
    width: 16px;
    height: 16px;
    padding: 0;
    color: var(--color-text-3);
  }
`
const ButtonContainer = styled.div<{ idPanel?: TGridInfoToolId }>`
  width: ${({ idPanel }) => (['SLIDE_LABEL', 'GLASS_PREVIEW'].includes(String(idPanel)) ? '40px' : 'auto')};
  display: flex;
  justify-content: space-between;
`
const TitleElement = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
`
const HeaderBlock = styled.div<{ idPanel?: TGridInfoToolId }>`
  display: flex;
  width: 100%;
  margin-bottom: ${({ idPanel }) =>
    ['MINIMAP', 'SLIDE_LABEL', 'GLASS_PREVIEW'].includes(String(idPanel)) ? '0' : '8px'};
  padding-bottom: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({ idPanel }) =>
    ['MINIMAP', 'SLIDE_LABEL', 'GLASS_PREVIEW'].includes(String(idPanel)) ? 'none' : '1px solid var(--color-border-1)'};
  cursor: move;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
`

/** Заголовок панели */
type ToolTitleOption = {
  /** Наименование панели */
  name: string
  /** Тип панели */
  type: TGridInfoTool
}

/** Стиль обрезающий уголок картинки при разных градусых поворота. Нужен для того, чтобы четче отоброазить треу */
export const getClipPath = (rotation: number) => {
  switch (rotation) {
    case 0:
      return `polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 12px), calc(100% - 12px) 100%, 0 100%, 0 100%, 0 0)`
    case RIGHT_ANGLE:
      return `polygon(0 0, calc(100% - 12px) 0%, 100% 12px, 100% 100%, 100% 100%, 0 100%, 0 100%, 0 0)`
    case STRAIGHT_ANGLE:
      return `polygon(12px 0%, 100% 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 100%, 0% 12px)`
    case RIGHT_ANGLE_X_3:
      return `polygon(0 0, 100% 0, 100% 0, 100% 100%, 100% 100%, 12px 100%, 0% calc(100% - 12px), 0 0)`
  }
}

export const ViewerInfoToolPanelContainer: React.FC<Props> = ({
  children,
  disableDrag,
  footer,
  id,
  initPosition,
  mapSizes,
  onClickHandlerRotate,
  onPositionChange,
  panelRef,
  position,
  setPosition,
  setSizes,
  style,
  title,
  type,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { activeViewerId: viewerId } = useViewerPageProvided()
  const viewerDispatch = useViewerDispatch(viewerId)
  const closeHandler = (id: TGridInfoTool) => {
    id === LayerType.MITOSIS && viewerDispatch(viewerSlice.actions.setSelectedAnnotationsIds([]))
    dispatch(viewerPageSlice.actions.toggleTool(id))
  }
  // TODO: Это точно всегда правильно работать будет?
  // Стоит задуматься над уменьшением вложенности для панелей
  const parentBlock = panelRef?.current?.parentElement?.parentElement?.parentElement?.parentElement
  const { overviewModalWidth } = useTypedSelector((state) => state.viewerPage)

  const options: ToolTitleOption[] = [
    {
      name: t('Обзор слайда'),
      type: 'OVERVIEW',
    },
    {
      name: t('Этикетка'),
      type: 'SLIDE_LABEL',
    },
    {
      name: t('Фото стекла'),
      type: 'GLASS_PREVIEW',
    },
    {
      name: t('Информация о слайде'),
      type: 'SLIDE_INFO',
    },
    {
      name: t('Подсчет клеток'),
      type: 'MITOSIS',
    },
    {
      name: t('Оценка экспрессии ядерных биомаркеров'),
      type: 'KI67',
    },
    {
      name: t('Подсчет суммы баллов по Глисону'),
      type: 'SEGMENTATION',
    },
    {
      name: t('Прозрачность маски похожести'),
      type: 'HEAT_MAP',
    },
    {
      name: t('Поиск похожей морфологии'),
      type: 'SEARCH_MORPHOLOGY',
    },
    {
      name: t('Сегментация ткани ПЖ'),
      type: 'PV_PROSTATE',
    },
    {
      name: t('Детекция артефактов'),
      type: 'ARTEFACTS',
    },
    {
      name: t('Детекция метастазов в ЛУ'),
      type: 'MEDICALNEURONETS_CRC',
    },
  ]

  //set overview size at first open
  useEffect(() => {
    if (overviewModalWidth && setSizes) setSizes([overviewModalWidth, overviewModalWidth])
  }, [overviewModalWidth])
  return (
    <Draggable
      id={id}
      panelRef={panelRef}
      initPosition={initPosition}
      onPositionChange={onPositionChange}
      disableDrag={disableDrag}
      parentBlock={parentBlock}
      style={style}
      mapSizes={mapSizes}
      setPosition={setPosition}
      position={position}
      isFitContent={['OVERVIEW', 'SLIDE_LABEL', 'GLASS_PREVIEW'].includes(type)}
    >
      <HeaderBlock idPanel={id} id={'header_tool_panel'}>
        <TitleElement>{title || options.find((item) => item.type === type)?.name}</TitleElement>
        <ButtonContainer idPanel={id}>
          {['SLIDE_LABEL', 'GLASS_PREVIEW'].includes(String(id)) && (
            <IconButton type={'text'} onClick={onClickHandlerRotate} icon={<IconElement size={'md'} name="flip" />} />
          )}
          {id !== 'VALIDATION' && (
            <IconButton
              type="text"
              icon={<IconElement name="cross16" size={'md'} />}
              onClick={() => closeHandler(type)}
            />
          )}
        </ButtonContainer>
      </HeaderBlock>
      <div style={{ height: '100%' }} id={NON_CONTEXT_ID}>
        {children}
      </div>
      {['MINIMAP', 'IGNORE_IN_SCREENSHOT', 'SLIDE_LABEL', 'GLASS_PREVIEW'].includes(String(id)) ? (
        <IconBlock>
          <IconElement size={'lg'} name="resize" fill={'fff'} />
        </IconBlock>
      ) : null}
      {footer ? <Footer>{footer}</Footer> : null}
    </Draggable>
  )
}
