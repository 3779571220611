import FileUploadContainer from 'features/new-preview-panel/panel-preview-components/FileUploadContainer'
import PanelPreviewContainer from 'features/new-preview-panel/panel-preview-components/PanelPreviewContainer'
import WarningPreview from 'features/new-preview-panel/WarningPreview'
import { useSlideGrouped } from 'features/thumbnails/hooks'
import React, { memo } from 'react'
import ISlide, { SlideState } from 'types/ISlide'
import { ISlideGrouped } from 'types/ISlideGrouped'

/** Возвращает слайды имеющие статус PROCESSING и не имеющие блока */
function getProcessingSlides(slideGrouped?: ISlideGrouped[]): ISlide[] {
  return (
    slideGrouped?.reduce<ISlide[]>((acc, item) => {
      if (!item.block) {
        const processingSlides = item.slides.filter((slide) => slide.state === SlideState.PROCESSING)
        return acc.concat(processingSlides)
      }
      return acc
    }, []) || []
  )
}

const ImagesTabContent = memo(({ caseId, declinementComment }: { declinementComment?: string; caseId: number }) => {
  const { filesList, slideGrouped } = useSlideGrouped(caseId)
  const processingSlides = getProcessingSlides(slideGrouped)

  return (
    <div>
      {declinementComment && <WarningPreview title={declinementComment} />}
      <div style={{ display: 'flex', flexWrap: 'wrap', margin: `0 16px` }}>
        {(!!filesList.length || !!processingSlides?.length) && (
          <FileUploadContainer filesList={filesList} processingSlides={processingSlides} />
        )}
        {slideGrouped?.map((item, index) => (
          <PanelPreviewContainer key={index} blocks={item.slides} caseId={caseId} />
        ))}
      </div>
    </div>
  )
})

export default ImagesTabContent
