import { useTypedSelector } from 'app/redux/lib/selector'
import { slideService, useSlideCache } from 'entities/slide'
import { useMutateSlideCaseLabelRotation } from 'entities/slide/api/query'
import { useAnnotationsByCaseQuery } from 'features/annotations'
import { useCaseQuery } from 'features/cases'
import { useUserStatusContext } from 'features/multiplayer/lib'
import { notices } from 'features/notices'
import { refetch, updateRotate } from 'features/thumbnails/common/utils'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import { selectUrlCaseId, viewerPageSlice } from 'pages/viewer'
import { useOpenViewers, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { VIEWERS_SET } from 'pages/viewer/model/viewerPageSlice'
import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { QUERY_TYPE } from 'shared/api'
import { useDeleteSlide } from 'shared/lib/hooks'
import useRestoreSlide from 'shared/lib/hooks/useRestoreSlide'
import { IconElement, SpinElement } from 'shared/ui/kit'
import ICase, { ICaseRelation } from 'types/ICase'
import IUserRole from 'types/IUserRole'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, viewerSlice } from 'viewer/container'

import { ContextMenuItem, ContextMenuWrapper, StyledDivider } from './ContextThumbnail.styles'
import { ContextMenuItemComponentProps, MenuItemAction, Props } from './ContextThumbnail.types'
import { filterItems } from './helpers'
import { useContent } from './useContent'

/** максимальное число открытых вьюверов */
const MAX_SIMULTANEOUS_VIEWERS = 4

const ContextMenuItemComponent: React.FC<ContextMenuItemComponentProps> = ({
  isDisabled,
  name,
  onClick,
  text,
}): ReactElement => (
  <ContextMenuItem data-testid={`${name.toLowerCase()}-command`} isDisabled={isDisabled} name={name} onClick={onClick}>
    <IconElement size={'md'} name={name} />
    {text}
  </ContextMenuItem>
)

const ContextThumbnail = ({
  addToSplitScreenDisable,
  menuRef,
  onCloseMenu,
  position = '',
  similarThumbnailHover,
  slideThumbnailHover,
  thumbnailMenuPosition,
}: Props) => {
  const queryClient = useQueryClient()
  const urlCaseId = useSelector(selectUrlCaseId)
  const { openViewerIds } = useOpenViewers()
  const viewerDispatch = useViewerDispatch(VIEWERS_SET[openViewerIds.length] as TViewerId)
  const workspaceId = useCurrentWorkspaceId()
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace) || null
  const { addViewer } = useViewerPageProvided()
  const { caseId, groupType, labelUrl, slideId, source } = slideThumbnailHover || {}
  const authorities = useTypedSelector((state) => state.user.authorities)
  const { currentTab } = useCaseManagementRouteParam()

  const isImageManager = authorities?.includes(IUserRole.ROLE_IMAGE_MANAGER)
  const slideCaseId = caseId || urlCaseId

  const [isLoading, setLoading] = useState(false)
  const labels = useTypedSelector((state) => state.viewerPage.label)
  const { mutate } = useMutateSlideCaseLabelRotation(slideCaseId || 0, slideId || 0)

  const location = useLocation()
  const isDashboardLocation = location.pathname === '/dashboard'
  const { refetch: refetchCase } = useCaseQuery({ caseId: slideCaseId, source: 'PLATFORM' })
  const { refetch: refetchAnnotationsByCase } = useAnnotationsByCaseQuery(slideCaseId || 0, isDashboardLocation)
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, slideCaseId])
  const { permissionLevel, relation } = caseRecord || {}
  const isOwner = relation === ICaseRelation.OWNER
  const isRestricted = relation === ICaseRelation.RESTRICTED
  const isShared = relation === ICaseRelation.SHARED
  const isReadOnly = permissionLevel === 'READ_ONLY'

  const notificationKey = `${slideCaseId} ${slideId}`
  const slide = useSlideCache(Number(slideId))
  const { t } = useTranslation()
  const onSuccessHandler = () => refetch({ refetchAnnotationsByCase, refetchCase })
  const { mutateDeleteSlide } = useDeleteSlide({ caseId: slideCaseId, onSuccess: onSuccessHandler, slideId })
  const { mutateRestoreSlide } = useRestoreSlide({ caseId: slideCaseId, onSuccess: onSuccessHandler, slideId })

  const { unsubscribeFromUser } = useUserStatusContext()
  const menuItems = useContent(t)

  const getIsDisabled = (action: string, openViewerIds: TViewerId[]) =>
    openViewerIds.length === MAX_SIMULTANEOUS_VIEWERS && action === MenuItemAction.AddToSplitScreen

  const handleRotationChange = (degree: number) => {
    if (isOwner) {
      mutate(degree)
    }
    return
  }

  const onRestore = async () => {
    await mutateRestoreSlide()
    notices.close(notificationKey)
  }

  const onDelete = async () => {
    onCloseMenu()
    unsubscribeFromUser()
    await mutateDeleteSlide()
    notices.openOnSlideDeleteNotification({
      caption: slide?.slideMetadata?.commonMetadata?.caption,
      id: slide?.slideId,
      key: notificationKey,
      onRestore,
      restoreDisabled: slide?.state !== 'AVAILABLE',
      src: slide?.thumbnails.small,
    })
  }

  const onDownload = async () => {
    await onCloseMenu()
    if (slideCaseId && slideId) {
      const res = await slideService.downloadSlide(String(workspaceId), slideCaseId, [slideId])

      return res?.map((item) => {
        item.found && window.open(item.downloadURL)
      })
    }
  }

  const onAddViewer = (event: React.MouseEvent<HTMLElement>) => {
    onCloseMenu()
    unsubscribeFromUser()
    //@ts-ignore
    const similar = similarThumbnailHover?.similar
    if ((slideCaseId && slideId && source) || similar) {
      addViewer({
        caseId: !similar ? slideCaseId : similar.caseId,
        slideGroupType: groupType || 'MICRO',
        slideId: !similar ? slideId : similar.slideId,
        source: !similar ? source : similar.source,
        viewerMode: similarThumbnailHover ? 'SEARCH' : 'DEFAULT',
      })
    }
    if (similarThumbnailHover) {
      viewerDispatch(viewerSlice.actions.setSelectedBbox(similarThumbnailHover))
    }
    event.stopPropagation()
  }

  const updateAllLabelRotate = async (action: string) => {
    const updatedRotate = updateRotate(labels, action)
    const rotatedSlidesData = {
      data: Object.values(updatedRotate)
        .filter(({ labelUrl }) => labelUrl)
        .map(({ rotate, slideId }) => ({
          labelRotation: rotate,
          slideId: slideId || 0,
        })),
    }
    await setLoading(true)

    if (rotatedSlidesData && slideCaseId && groupType !== 'MACRO' && isOwner) {
      await slideService.updateRotateLabels({
        caseId: slideCaseId,
        data: rotatedSlidesData,
      })
    }

    await setLoading(false)
    return viewerDispatch(viewerPageSlice.actions.setNewSlides(updatedRotate))
  }

  const updateLabel = (action: string) => {
    const updatedRotate = updateRotate(labels, action, slideId)
    const newRotate = slideId && updatedRotate[slideId].rotate
    newRotate && handleRotationChange(newRotate)
    return viewerDispatch(viewerPageSlice.actions.setNewSlides(updatedRotate))
  }

  const handleClick = (action: string, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const actionsList: { [key: string]: () => void } = {
      addToSplitScreen: () => !getIsDisabled(action, openViewerIds) && onAddViewer(event),
      delete: onDelete,
      download: onDownload,
      rotateAllLabels: () => updateAllLabelRotate(action),
      rotateLabel: () => updateLabel(action),
    }

    return actionsList[action]()
  }

  return (
    <ContextMenuWrapper thumbnailMenuPosition={thumbnailMenuPosition} position={position} ref={menuRef}>
      <SpinElement spinning={isLoading}>
        {menuItems
          .filter((item) =>
            filterItems(
              item,
              currentWorkspace,
              isImageManager,
              isReadOnly,
              groupType,
              labelUrl,
              similarThumbnailHover,
              isRestricted,
              currentTab,
              addToSplitScreenDisable,
              caseRecord,
              isShared,
            ),
          )
          .map((item) => (
            <React.Fragment key={item.name}>
              {groupType !== 'MACRO' && item.action === MenuItemAction.Download && <StyledDivider />}
              <ContextMenuItemComponent
                isDisabled={getIsDisabled(item.action, openViewerIds)}
                name={item.name}
                text={item.text}
                onClick={(e) => handleClick(item.action, e)}
              />
            </React.Fragment>
          ))}
      </SpinElement>
    </ContextMenuWrapper>
  )
}

export default ContextThumbnail
