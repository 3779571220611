import { useTypedSelector } from 'app/redux/lib/selector'
import { useSlideQuery } from 'entities/slide'
import { useMutateSlideCaseLabelRotation } from 'entities/slide/api/query'
import { updateRotate } from 'features/thumbnails/common/utils'
import { MenuItemAction } from 'features/thumbnails/ui/ContextThumbnail/ContextThumbnail.types'
import { viewerPageSlice } from 'pages/viewer'
import { Resizable } from 're-resizable'
import { Direction } from 're-resizable/lib/resizer'
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { SpinElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ICase, { ICaseRelation } from 'types/ICase'
import ISource from 'types/ISource'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, useViewerMainSelector } from 'viewer/container'
import { MARGIN_BORDER, RESIZABLE_OFFSET } from 'viewer/tools/ui/lib/constans'
import { ViewerInfoToolPanelContainer } from 'viewer/tools/ui/ViewerInfoToolPanelContainer'

import { LabelInfoContainer } from './LabelInfoContainer'

/** минимальная ширина панели */
const MIN_WIDTH_LABEL = 220
/** начальное состояние */
const INITIAL_SIZES = [248, 0]

const StyledResizable = styled(Resizable)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

type Props = {
  /** slideLabelRef - ссылка на элемент штрихкода */
  slideLabelRef: React.RefObject<HTMLDivElement>
  /** slideId - идентификатор слайда в текущем вьювере */
  slideId: number
  /** caseId - идентификатор случая */
  caseId: number
  /** viewerId - идентификатор вьювера */
  viewerId: TViewerId
  /** source - источник слайда */
  source: ISource
  /** initPosition - координаты блока */
  initPosition: number[]
  /** onPositionChange - устновка параметров координат блока */
  onPositionChange: (position: number[]) => void
}
export const LabelInfoToolPanel = ({
  caseId,
  initPosition,
  onPositionChange,
  slideId,
  slideLabelRef,
  source,
  viewerId,
}: Props) => {
  const queryClient = useQueryClient()
  const viewerDispatch = useViewerDispatch(viewerId)
  const { data: slide } = useSlideQuery({ slideId, source })
  const { isScreenshotModalOpen } = useViewerMainSelector(viewerId)
  const [position, setPosition] = useState<number[]>(initPosition || [0, 0])
  const [linkLabelState, setLinkLabelState] = useState(slide?.labelUrl || '')
  const [isLoading, setIsLoading] = useState(false)
  const [rotation, setRotation] = useState<number>()
  const labels = useTypedSelector((state) => state.viewerPage.label)
  const labelRotate = labels[slideId]?.rotate || 0
  const [backgroundSizes, setBackgroundSizes] = useState(INITIAL_SIZES)
  const getParentBlock = () => slideLabelRef?.current?.parentElement?.parentElement?.parentElement?.parentElement
  const [maxHeight, setMaxHeight] = useState<number>(0)
  const [maxWidth, setMaxWidth] = useState<number>(0)
  const parentBlockBottomCoordinate = getParentBlock()?.getBoundingClientRect().bottom || 0
  const parentBlockRightCoordinate = getParentBlock()?.getBoundingClientRect().right || 0

  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
  const isOwner = caseRecord?.relation === ICaseRelation.OWNER
  const { mutate } = useMutateSlideCaseLabelRotation(caseId || 0, slideId || 0)

  useEffect(() => {
    setRotation(labelRotate)
  }, [labelRotate, slideId])

  const resizeLabelTool = (el?: HTMLElement) => {
    const editBoxElement = document.getElementsByClassName('edit-box')[0]
    const toolElement = el || editBoxElement?.parentElement
    // @ts-ignore
    const { height, left, top, width } = toolElement.getBoundingClientRect()
    const bottomCoordinate = height + top
    const rightCoordinate = width + left

    if (
      (bottomCoordinate > parentBlockBottomCoordinate - MARGIN_BORDER ||
        rightCoordinate > parentBlockRightCoordinate - MARGIN_BORDER) &&
      toolElement?.style.width &&
      toolElement?.style.height
    ) {
      const newWidth = +toolElement?.style?.width.slice(0, -2)
      const newHeight = +toolElement?.style?.height.slice(0, -2)
      setBackgroundSizes([newWidth, newHeight])
    } else {
      setBackgroundSizes([width, height])
    }
  }
  const onResizeHandler = (w: MouseEvent | TouchEvent, direction: Direction, el: HTMLElement) => resizeLabelTool(el)

  const getParentBlockSizes = () => {
    const parentBlock = getParentBlock()

    return [parentBlock?.getBoundingClientRect().width || 0, parentBlock?.getBoundingClientRect().height || 0]
  }
  const parentBlockHeight = getParentBlockSizes()[1]
  const parentBlockWidth = getParentBlockSizes()[0]

  useEffect(() => {
    setMaxWidth(parentBlockWidth - RESIZABLE_OFFSET)
    setMaxHeight(parentBlockHeight - RESIZABLE_OFFSET)
  }, [parentBlockWidth, parentBlockHeight])

  const onClickHandlerRotate = () => {
    const updatedRotate = updateRotate(labels, MenuItemAction.RotateLabel, slideId)
    const newRotate = slideId && updatedRotate[slideId].rotate
    newRotate !== undefined && isOwner && mutate(newRotate)
    return viewerDispatch(viewerPageSlice.actions.setNewSlides(updatedRotate))
  }

  useEffect(() => {
    const { labelUrl, proxiedLabelUrl } = slide || {}

    if (proxiedLabelUrl && isScreenshotModalOpen === true) {
      setLinkLabelState(proxiedLabelUrl)
      return
    }
    if (labelUrl && linkLabelState === proxiedLabelUrl && isScreenshotModalOpen === false) {
      setIsLoading(true)
      setLinkLabelState(labelUrl)

      const timeoutId = setTimeout(() => {
        setIsLoading(false)
      }, 1000)

      return () => clearTimeout(timeoutId)
    }

    if (labelUrl && labelUrl !== linkLabelState) {
      setLinkLabelState(labelUrl)
    }
  }, [slideId, isScreenshotModalOpen])

  if (!slide?.labelUrl && !slide?.proxiedLabelUrl) return null

  return (
    <ViewerInfoToolPanelContainer
      panelRef={slideLabelRef}
      initPosition={initPosition}
      onPositionChange={onPositionChange}
      onClickHandlerRotate={onClickHandlerRotate}
      type={'SLIDE_LABEL'}
      id={'SLIDE_LABEL'}
      position={position}
      setPosition={setPosition}
      mapSizes={backgroundSizes}
    >
      <SpinElement spinning={isLoading}>
        <StyledResizable
          minWidth={MIN_WIDTH_LABEL}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          lockAspectRatio={true}
          size={{
            height: backgroundSizes[1] ? backgroundSizes[1] : '',
            width: backgroundSizes[0] ? backgroundSizes[0] : '',
          }}
          handleStyles={{ bottomRight: { bottom: -5, right: -5, zIndex: 10 } }}
          onResize={onResizeHandler}
          enable={{ bottomRight: true }}
        >
          <LabelInfoContainer
            rotation={rotation}
            setRotation={(rotate) => setRotation(rotate)}
            slideId={slideId}
            caseId={caseId}
            source={source}
            labelSrc={linkLabelState}
            backGroundSizes={backgroundSizes}
            setBackGroundSizes={setBackgroundSizes}
            maxHeight={maxHeight}
          />
        </StyledResizable>
      </SpinElement>
    </ViewerInfoToolPanelContainer>
  )
}
