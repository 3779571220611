import { ReactComponent as one_mm } from './1mm.svg'
import { ReactComponent as two_mm } from './2mm.svg'
import { ReactComponent as add } from './add.svg'
import { ReactComponent as addExam } from './addExam.svg'
import { ReactComponent as addFrame } from './addFrame.svg'
import { ReactComponent as addImageSmall } from './addImageSmall.svg'
import { ReactComponent as addNote } from './addNote.svg'
import { ReactComponent as alert } from './alert.svg'
import { ReactComponent as angle } from './angle.svg'
import { ReactComponent as annotations } from './annotations.svg'
import { ReactComponent as annotationsList } from './annotationsList.svg'
import { ReactComponent as archive } from './archive.svg'
import { ReactComponent as archive16 } from './archive16.svg'
import { ReactComponent as archiveSmall } from './archiveSmall.svg'
import { ReactComponent as archiveSmall2 } from './archiveSmall2.svg'
import { ReactComponent as arrowBack } from './arrowBack.svg'
import { ReactComponent as arrowLeftSmall } from './arrowLeftSmall.svg'
import { ReactComponent as arrowReturn } from './arrowReturn.svg'
import { ReactComponent as arrowRight } from './arrowRight.svg'
import { ReactComponent as arrowRightSmall } from './arrowRightSmall.svg'
import { ReactComponent as atlas } from './atlas.svg'
import { ReactComponent as attention } from './attention.svg'
import { ReactComponent as bigTile } from './bigTile.svg'
import { ReactComponent as bin16 } from './bin16.svg'
import { ReactComponent as brightness } from './brightness.svg'
import { ReactComponent as calendar } from './calendar.svg'
import { ReactComponent as cameraSight } from './cameraSight.svg'
import { ReactComponent as Case } from './case.svg'
import { ReactComponent as checkmark } from './checkmark.svg'
import { ReactComponent as checkSmall } from './checkSmall.svg'
import { ReactComponent as checkSmall2 } from './checkSmall2.svg'
import { ReactComponent as circle } from './circle.svg'
import { ReactComponent as cito } from './cito.svg'
import { ReactComponent as clock } from './clock.svg'
import { ReactComponent as colorCorrection } from './colorCorrection.svg'
import { ReactComponent as comment } from './comment.svg'
import { ReactComponent as commentSmall } from './commentSmall.svg'
import { ReactComponent as contrast } from './contrast.svg'
import { ReactComponent as copySmall } from './copySmall.svg'
import { ReactComponent as coregDropDown } from './coregDropDown.svg'
import { ReactComponent as coregError } from './coregError.svg'
import { ReactComponent as counter } from './counter.svg'
import { ReactComponent as cross } from './cross.svg'
import { ReactComponent as cross16 } from './cross16.svg'
import { ReactComponent as deleteIcon } from './delete.svg'
import { ReactComponent as deleteFrame } from './deleteFrame.svg'
import { ReactComponent as deleteRed } from './deleteRed.svg'
import { ReactComponent as deleteSmall2 } from './deleteSmall2.svg'
import { ReactComponent as dislike } from './dislike.svg'
import { ReactComponent as dislikeFill } from './dislikeFill.svg'
import { ReactComponent as done } from './done.svg'
import { ReactComponent as dot } from './dot.svg'
import { ReactComponent as dots } from './dots.svg'
import { ReactComponent as downloadSmall } from './downloadSmall.svg'
import { ReactComponent as drag } from './drag.svg'
import { ReactComponent as drawArrow } from './drawArrow.svg'
import { ReactComponent as drawPen } from './drawPen.svg'
import { ReactComponent as dropDown } from './dropDown.svg'
import { ReactComponent as edit } from './edit.svg'
import { ReactComponent as edit16 } from './edit16.svg'
import { ReactComponent as editSmall } from './editSmall.svg'
import { ReactComponent as editSmall2 } from './editSmall2.svg'
import { ReactComponent as exclamationMark } from './exclamationMark.svg'
import { ReactComponent as exclude } from './exclude.svg'
import { ReactComponent as expand } from './expand.svg'
import { ReactComponent as eye } from './eye.svg'
import { ReactComponent as eyeClose } from './eyeClose.svg'
import { ReactComponent as fastMove } from './fastMove.svg'
import { ReactComponent as favorites } from './favorites.svg'
import { ReactComponent as feedBack } from './feedBack.svg'
import { ReactComponent as flip } from './flip.svg'
import { ReactComponent as flipHorizontally } from './flipHorizontally.svg'
import { ReactComponent as flipVertically } from './flipVertically.svg'
import { ReactComponent as folder } from './folder.svg'
import { ReactComponent as fullScreen } from './fullScreen.svg'
import { ReactComponent as fullScreenExit } from './fullScreenExit.svg'
import { ReactComponent as glassPreview } from './glassPreview.svg'
import { ReactComponent as googleChrome } from './googleChrome.svg'
import { ReactComponent as goTo } from './goTo.svg'
import { ReactComponent as guide } from './guide.svg'
import { ReactComponent as headerFilter } from './headerFilter.svg'
import { ReactComponent as headerSelectedFilter } from './headerSelectedFilter.svg'
import { ReactComponent as help } from './help.svg'
import { ReactComponent as helpSmall } from './helpSmall.svg'
import { ReactComponent as history } from './history.svg'
import { ReactComponent as historySmall } from './historySmall.svg'
import { ReactComponent as imageList } from './imageList.svg'
import { ReactComponent as imagesSmall } from './imagesSmall.svg'
import { ReactComponent as inbox } from './inbox.svg'
import { ReactComponent as info } from './info.svg'
import { ReactComponent as infoSmall } from './infoSmall.svg'
import { ReactComponent as inside } from './inside.svg'
import { ReactComponent as intersect } from './intersect.svg'
import { ReactComponent as issueDate } from './issueDate.svg'
import { ReactComponent as jpeg } from './jpeg.svg'
import { ReactComponent as keyboard } from './keyboard.svg'
import { ReactComponent as ki67 } from './ki67.svg'
import { ReactComponent as ki67_green_dot } from './ki67_green_dot.svg'
import { ReactComponent as ki67_red_plus } from './ki67_red_plus.svg'
import { ReactComponent as ki67_white_dot } from './ki67_white_dot.svg'
import { ReactComponent as ki67cross } from './ki67cross.svg'
import { ReactComponent as ki67rectangle } from './ki67rectangle.svg'
import { ReactComponent as label } from './label.svg'
import { ReactComponent as language } from './language.svg'
import { ReactComponent as languageSmall } from './languageSmall.svg'
import { ReactComponent as layers } from './layers.svg'
import { ReactComponent as leftSideBar } from './leftSideBar.svg'
import { ReactComponent as like } from './like.svg'
import { ReactComponent as likeFill } from './likeFill.svg'
import { ReactComponent as link } from './link.svg'
import { ReactComponent as linkSmall } from './linkSmall.svg'
import { ReactComponent as list } from './list.svg'
import { ReactComponent as logoutSmall } from './logoutSmall.svg'
import { ReactComponent as loupe } from './loupe.svg'
import { ReactComponent as mail } from './mail.svg'
import { ReactComponent as management } from './management.svg'
import { ReactComponent as manufacturer } from './manufacturer.svg'
import { ReactComponent as map } from './map.svg'
import { ReactComponent as marking } from './marking.svg'
import { ReactComponent as markingSmall } from './markingSmall.svg'
import { ReactComponent as mdt } from './mdt.svg'
import { ReactComponent as mitosis } from './mitosis.svg'
import { ReactComponent as mitosisAnnotation } from './mitosis_annotation.svg'
import { ReactComponent as mitosis_blue_circle } from './mitosis_blue_circle.svg'
import { ReactComponent as mitosis_green_circle } from './mitosis_green_circle.svg'
import { ReactComponent as moon } from './moon.svg'
import { ReactComponent as more } from './more.svg'
import { ReactComponent as mouseCenter } from './mouseCenter.svg'
import { ReactComponent as mouseCenterLight } from './mouseCenterLight.svg'
import { ReactComponent as mouseLeft } from './mouseLeft.svg'
import { ReactComponent as mouseLeftLight } from './mouseLeftLight.svg'
import { ReactComponent as mouseRight } from './mouseRight.svg'
import { ReactComponent as mouseRightLight } from './mouseRightLight.svg'
import { ReactComponent as multipolygon } from './multipolygon.svg'
import { ReactComponent as navigateLeft } from './navigateLeft.svg'
import { ReactComponent as navigateLeftDouble } from './navigateLeftDouble.svg'
import { ReactComponent as navigateRight } from './navigateRight.svg'
import { ReactComponent as navigateRightDouble } from './navigateRightDouble.svg'
import { ReactComponent as newCase } from './newCase.svg'
import { ReactComponent as newWindow } from './newWindow.svg'
import { ReactComponent as notAttention } from './notAttention.svg'
import { ReactComponent as notification } from './notification.svg'
import { ReactComponent as notStar } from './notStar.svg'
import { ReactComponent as numberDown } from './numberDown.svg'
import { ReactComponent as numberDownFilled } from './numberDownFilled.svg'
import { ReactComponent as numberUp } from './numberUp.svg'
import { ReactComponent as numberUpFilled } from './numberUpFilled.svg'
import { ReactComponent as objects } from './objects.svg'
import { ReactComponent as overview } from './overview.svg'
import { ReactComponent as password } from './password.svg'
import { ReactComponent as pause } from './pause.svg'
import { ReactComponent as paused } from './paused.svg'
import { ReactComponent as pdf } from './pdf.svg'
import { ReactComponent as pen } from './pen.svg'
import { ReactComponent as person } from './person.svg'
import { ReactComponent as pointer } from './pointer.svg'
import { ReactComponent as pointer2 } from './pointer2.svg'
import { ReactComponent as preview } from './preview.svg'
import { ReactComponent as printScreen } from './printScreen.svg'
import { ReactComponent as questionCircle } from './questionCircle.svg'
import { ReactComponent as questionCircleSolid } from './questionCircleSolid.svg'
import { ReactComponent as rcInfo } from './rcInfo.svg'
import { ReactComponent as rectangle } from './rectangle.svg'
import { ReactComponent as refuse } from './refuse.svg'
import { ReactComponent as reject } from './reject.svg'
import { ReactComponent as releaseVersion } from './releaseVersion.svg'
import { ReactComponent as relink } from './relink.svg'
import { ReactComponent as reportSmall } from './reportSmall.svg'
import { ReactComponent as resize } from './resize.svg'
import { ReactComponent as resizeVertical } from './resizeVertical.svg'
import { ReactComponent as rightSideBar } from './rightSideBar.svg'
import { ReactComponent as rotate } from './rotate.svg'
import { ReactComponent as rotate90AllSmall } from './rotate90AllSmall.svg'
import { ReactComponent as rotate90Left } from './rotate90Left.svg'
import { ReactComponent as rotate90Right } from './rotate90Right.svg'
import { ReactComponent as rotate90Small } from './rotate90Small.svg'
import { ReactComponent as ruler } from './ruler.svg'
import { ReactComponent as sadEmoji } from './sadEmoji.svg'
import { ReactComponent as saveSmall } from './saveSmall.svg'
import { ReactComponent as screenRecord } from './screenRecord.svg'
import { ReactComponent as screenRecordInProgress } from './screenRecordInProgress.svg'
import { ReactComponent as search } from './search.svg'
import { ReactComponent as searchAtlas } from './searchAtlas.svg'
import { ReactComponent as searchCase } from './searchCase.svg'
import { ReactComponent as sectionIsClose } from './sectionIsClose.svg'
import { ReactComponent as sectionIsOpen } from './sectionIsOpen.svg'
import { ReactComponent as share } from './share.svg'
import { ReactComponent as share16 } from './share16.svg'
import { ReactComponent as shareSmall } from './shareSmall.svg'
import { ReactComponent as shareSmallCircle } from './shareSmallCircle.svg'
import { ReactComponent as signed } from './signed.svg'
import { ReactComponent as similar } from './similar.svg'
import { ReactComponent as simplification } from './simplification.svg'
import { ReactComponent as smallTile } from './smallTile.svg'
import { ReactComponent as splitAdd } from './splitAdd.svg'
import { ReactComponent as splitSmall } from './splitSmall.svg'
import { ReactComponent as star16 } from './star16.svg'
import { ReactComponent as stats } from './stats.svg'
import { ReactComponent as subtact } from './subtact.svg'
import { ReactComponent as sum } from './sum.svg'
import { ReactComponent as summary } from './summary.svg'
import { ReactComponent as sun } from './sun.svg'
import { ReactComponent as support } from './support.svg'
import { ReactComponent as supportChat } from './supportChat.svg'
import { ReactComponent as tasks } from './tasks.svg'
import { ReactComponent as text } from './text.svg'
import { ReactComponent as textArea } from './textArea.svg'
import { ReactComponent as thumb } from './thumb.svg'
import { ReactComponent as tools } from './tools.svg'
import { ReactComponent as toolsAI } from './toolsAI.svg'
import { ReactComponent as uncito } from './uncito.svg'
import { ReactComponent as undoSmall } from './undoSmall.svg'
import { ReactComponent as union } from './union.svg'
import { ReactComponent as unsaveSmall } from './unsaveSmall.svg'
import { ReactComponent as upload } from './upload.svg'
import { ReactComponent as verticalSeparator } from './verticalSeparator.svg'
import { ReactComponent as yandexBrowser } from './yandexBrowser.svg'
import { ReactComponent as zoom } from './zoom.svg'

const icons = {
  Case,
  add,
  addExam,
  addFrame,
  addImageSmall,
  addNote,
  alert,
  angle,
  annotations,
  annotationsList,
  archive,
  archive16,
  archiveSmall,
  archiveSmall2,
  arrowBack,
  arrowLeftSmall,
  arrowReturn,
  arrowRight,
  arrowRightSmall,
  atlas,
  attention,
  bigTile,
  bin16,
  brightness,
  calendar,
  cameraSight,
  checkSmall,
  checkSmall2,
  checkmark,
  circle,
  cito,
  clock,
  colorCorrection,
  comment,
  commentSmall,
  contrast,
  copySmall,
  coregDropDown,
  coregError,
  counter,
  cross,
  cross16,
  delete: deleteIcon,
  deleteFrame,
  deleteRed,
  deleteSmall2,
  dislike,
  dislikeFill,
  done,
  dot,
  dots,
  downloadSmall,
  drag,
  drawArrow,
  drawPen,
  dropDown,
  edit,
  edit16,
  editSmall,
  editSmall2,
  exclamationMark,
  exclude,
  expand,
  eye,
  eyeClose,
  fastMove,
  favorites,
  feedBack,
  flip,
  flipHorizontally,
  flipVertically,
  folder,
  fullScreen,
  fullScreenExit,
  goTo,
  googleChrome,
  guide,
  headerFilter,
  headerSelectedFilter,
  help,
  helpSmall,
  history,
  historySmall,
  imageList,
  imagesSmall,
  inbox,
  info,
  infoSmall,
  inside,
  intersect,
  issueDate,
  jpeg,
  keyboard,
  ki67,
  ki67_green_dot,
  ki67_red_plus,
  ki67_white_dot,
  ki67cross,
  ki67rectangle,
  label,
  language,
  languageSmall,
  layers,
  leftSideBar,
  like,
  likeFill,
  link,
  linkSmall,
  list,
  logoutSmall,
  loupe,
  mail,
  management,
  manufacturer,
  map,
  marking,
  markingSmall,
  mdt,
  mitosis,
  mitosisAnnotation,
  mitosis_blue_circle,
  mitosis_green_circle,
  moon,
  more,
  mouseCenter,
  mouseCenterLight,
  mouseLeft,
  mouseLeftLight,
  mouseRight,
  mouseRightLight,
  multipolygon,
  navigateLeft,
  navigateLeftDouble,
  navigateRight,
  navigateRightDouble,
  newCase,
  newWindow,
  notAttention,
  notStar,
  notification,
  numberDown,
  numberDownFilled,
  numberUp,
  numberUpFilled,
  objects,
  one_mm,
  overview,
  password,
  pause,
  paused,
  pdf,
  pen,
  person,
  pointer,
  pointer2,
  preview,
  printScreen,
  questionCircle,
  questionCircleSolid,
  rcInfo,
  rectangle,
  refuse,
  reject,
  releaseVersion,
  relink,
  reportSmall,
  resize,
  resizeVertical,
  rightSideBar,
  glassPreview,
  rotate,
  rotate90AllSmall,
  rotate90Left,
  rotate90Right,
  rotate90Small,
  ruler,
  sadEmoji,
  saveSmall,
  screenRecord,
  screenRecordInProgress,
  search,
  searchAtlas,
  searchCase,
  sectionIsClose,
  sectionIsOpen,
  share,
  share16,
  shareSmall,
  shareSmallCircle,
  signed,
  similar,
  simplification,
  smallTile,
  splitAdd,
  splitSmall,
  star16,
  stats,
  subtact,
  sum,
  summary,
  sun,
  support,
  supportChat,
  tasks,
  text,
  textArea,
  thumb,
  tools,
  toolsAI,
  two_mm,
  uncito,
  undoSmall,
  union,
  unsaveSmall,
  upload,
  verticalSeparator,
  yandexBrowser,
  zoom,
}

export default icons
