import { Divider } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useTaskQuery } from 'entities/tasks/api/query'
import { useCaseBioMaterialsQuery, useCaseDocumentsQuery, useCaseQuery, useMutateCase } from 'features/cases/api/query'
import { IFile } from 'features/cases/api/service'
import { CaseForm, CaseFormDataType } from 'features/cases/ui/CaseForm'
import { ReportsWidgetHeaderContainer } from 'features/reports'
import ReportInfoContainer from 'features/reports/ReportInfoContainer'
import { selectTasksViewerUrlTaskId, selectUrlCaseId } from 'pages/viewer'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { ModalProvider } from 'shared/ui/modal'
import styled from 'styled-components/macro'
import { ICaseRelation } from 'types/ICase'
import { IPatientDTO } from 'types/IPatient'
import ISource from 'types/ISource'

import Biomaterials from './ui/Biomaterials'
import CaseInfo from './ui/CaseInfo'
import FilesInfo from './ui/FilesInfo'
import PatientInfo from './ui/PatientInfo'

type Props = {
  caseId: number
  source: ISource
  isViewer?: boolean
  /** Флаг управления рендерером заключений */
  skipReports?: boolean
  /** Флаг управления рендерером информацией о кейсе */
  skipCaseInfo?: boolean
}

const StyledDivider = styled(Divider)`
  margin: 8px 0 8px !important;
`
const MainBlock = styled.div``
const StyledContentBlock = styled.div`
  overflow: auto;
`
const CaseInfoContainer = ({ caseId, isViewer, skipCaseInfo, skipReports, source }: Props) => {
  const [activeKeys] = useState(['info', 'reports'])
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const urlCaseId = useSelector(selectUrlCaseId) || caseId
  const { data: task } = useTaskQuery(taskId)
  const { data: caseRecord, isLoading } = useCaseQuery({ caseId: urlCaseId, source })
  const { data: caseRecordDocs } = useCaseDocumentsQuery({ caseId: urlCaseId, source })
  const { mutateAsync } = useMutateCase(caseId)
  const isRestricted = caseRecord?.relation === ICaseRelation.RESTRICTED
  const copyDisable = task?.status === 'PAUSED' || caseRecord?.permissionLevel === 'READ_ONLY' || isRestricted
  const onSave = async (data: CaseFormDataType, newFiles: IFile[], deletedFiles?: IFile[]) => {
    await mutateAsync({ data, deletedFiles, newFiles })
    caseFormModal.current.close()
  }
  const caseFormModal = useRef<any>()
  const reportCreationModal = useRef<any>()
  const isReportsCollapsed = activeKeys.findIndex((key) => key === 'reports') === -1
  const { casePathologicalInfo, caseType, materialTakeoutDate, name, patient, relation } = caseRecord || {}
  const workspaceId = useTypedSelector((state) => state.workspaces.currentWorkspace?.workspaceId)
  const { data: caseBiomaterial } = useCaseBioMaterialsQuery({
    caseId: caseId,
    workspaceId: Number(workspaceId),
  })

  return caseRecord ? (
    <MainBlock>
      {!skipCaseInfo && (
        <>
          <PatientInfo patient={patient as IPatientDTO} />
          {!!patient && <StyledDivider />}
          <FilesInfo files={caseRecordDocs} caseId={caseRecord.caseId} />
          {!!caseRecordDocs?.length && <StyledDivider />}
          <Biomaterials biomaterials={caseBiomaterial} fieldSet={caseRecord.fieldSet} />
          {!!caseBiomaterial?.length && <StyledDivider />}
          <CaseInfo caseRecord={caseRecord} isLoading={isLoading} isViewer={isViewer} />
          {relation === ICaseRelation.OWNER && (
            <StyledContentBlock>
              {/* Поля в информации о случае нельзя редактировать для другой мастер системы */}
              <ModalProvider ref={caseFormModal} centered={true} footer={null} closable={false} destroyOnClose>
                <CaseForm
                  onSave={onSave}
                  isEdit={true}
                  onCancel={() => caseFormModal.current.close()}
                  initialValues={{
                    casePathologicalInfo,
                    caseType,
                    materialTakeoutDate: materialTakeoutDate ? new Date(caseRecord.materialTakeoutDate) : undefined,
                    name: name || '',
                    patient,
                  }}
                  caseRecordDocs={caseRecordDocs}
                />
              </ModalProvider>
            </StyledContentBlock>
          )}
        </>
      )}
      {!skipReports && !skipCaseInfo && (
        <>
          <StyledDivider />
          <ReportsWidgetHeaderContainer
            reportCreationModalRef={reportCreationModal}
            caseId={caseId}
            isCollapsed={isReportsCollapsed}
            source={source}
            copyDisable={copyDisable}
            isRestricted={isRestricted}
          />
        </>
      )}
      {!skipReports && (
        <ReportInfoContainer
          skipCaseInfo={skipCaseInfo}
          reportCreationModalRef={reportCreationModal}
          caseId={caseId}
          patient={caseRecord?.patient}
          source={source}
          copyDisable={copyDisable}
          isRestricted={isRestricted}
        />
      )}
    </MainBlock>
  ) : null
}

export default CaseInfoContainer
